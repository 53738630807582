import React, { useEffect, useState } from 'react';

import { InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Alert, Button, Card, List, Pagination, Space, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { SubmitStatus } from '../../constants';
import authHttpInteractor from '../../interactors/authHttpInteractor';
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout';
import { ValidationUtility } from '../../utilities/ValidationUtility';

import { EditMessageDrawer } from './parts/EditMessageDrawer';
import { SendMessageDrawer } from './parts/SendMessageDrawer';

import 'react-quill/dist/quill.snow.css';

function SentMessageListPage() {
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const pageNo = pageParam && ValidationUtility.isNumber(pageParam) ? pageParam : 1;

  // TODO 共通化
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const [errorMsg, setErrorMsg] = useState();

  // const { getJwtPayload } = useAuth();
  // const [allSubCompanyEmployeeList, setAllSubCompanyEmployeeList] = useState([]);
  const [sentMessage, setSentMessage] = useState([]);

  // 送信Drawer開閉
  const [openSendMessageDrawer, setOpenSendMessageDrawer] = useState(false);
  // 編集Drawer開閉
  const [openEditMessageDrawer, setOpenEditMessageDrawer] = useState(false);
  const [currentMessage, setCurrentMessage] = useState();

  const fetchSendMessage = () => {
    authHttpInteractor
      .get(`/api/internalMessage/sent?pageNo=${pageNo}`)
      .then(response => {
        setSentMessage(response.data);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        setSubmitStatus(SubmitStatus.FAILURE);

        setErrorMsg(
          <>
            大変申し訳ありませんが、エラーを発生しました。
            <br />
            通信状況をご確認の上、再度お試しください。
          </>,
        );
      });
  };

  // 受信メッセージ一覧を取得
  useEffect(() => {
    // fetchAllSubCompnayEmployee();
    fetchSendMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'タイトル',
      dataIndex: 'title',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setCurrentMessage(record);
            setOpenEditMessageDrawer(true);
          }}
        >
          {record.title}
        </Button>
      ),
    },
    {
      title: '送信日時',
      dataIndex: 'createdAt',
      render: (_, record) => <>{dayjs(record.createdAt).format('YYYY/MM/DD HH:mm:ss')}</>,
    },
    {
      title: '更新日時',
      dataIndex: 'updatedAt',
      render: (_, record) => <>{dayjs(record.updatedAt).format('YYYY/MM/DD HH:mm:ss')}</>,
    },
  ];

  const content = () => (
    <div>
      {/* FIXME GlobalLayout に移動 */}
      {/* エラーメッセージ */}
      {submitStatus === SubmitStatus.FAILURE && (
        <div style={{ width: 800, marginBottom: 30 }}>
          <Alert message="エラー" description={errorMsg} type="error" showIcon />
        </div>
      )}

      <div style={{ width: 800 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Space wrap>
            <Title level={4}>送信箱</Title>
            <Button
              type="primary"
              onClick={() => {
                setOpenSendMessageDrawer(true);
              }}
              style={{ marginLeft: 10, marginBottom: 10 }}
            >
              メッセージ作成
            </Button>
          </Space>

          <Button type="link" size="large" icon={<InboxOutlined />} href="/message/inbox/">
            受信箱 ＞
          </Button>
        </div>

        <SendMessageDrawer
          isOpen={openSendMessageDrawer}
          onClose={() => {
            setOpenSendMessageDrawer(false);
          }}
          onEditSuccess={() => {
            setOpenSendMessageDrawer(false);
            fetchSendMessage();
          }}
        />

        <Table
          columns={columns}
          expandable={{
            expandedRowRender: record => (
              <>
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: record.body }} />
                {record.attachedFiles.length > 0 && (
                  <Card>
                    <List
                      header={<div>添付ファイル</div>}
                      itemLayout="horizontal"
                      size="small"
                      dataSource={record.attachedFiles}
                      renderItem={file => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<PaperClipOutlined />}
                            title={<a href={file.downloadUrl}>{file.name}</a>}
                          />
                        </List.Item>
                      )}
                    />
                  </Card>
                )}
              </>
            ),
          }}
          dataSource={sentMessage.messageList}
          pagination={false}
          rowKey="id"
        />

        <Pagination
          total={sentMessage.totalCount}
          current={sentMessage.pageNo}
          defaultPageSize={sentMessage.pageSize}
          showSizeChanger={false}
          onChange={page => {
            window.location.href = `/message/sent/?page=${page}`;
          }}
          style={{ marginTop: 20 }}
        />

        <EditMessageDrawer
          isOpen={openEditMessageDrawer}
          messageItem={currentMessage}
          onClose={() => {
            setOpenEditMessageDrawer(false);
          }}
          onEditSuccess={() => {
            setOpenEditMessageDrawer(false);
            fetchSendMessage();
          }}
        />
      </div>
    </div>
  );

  const breadcrumbList = [
    {
      title: <a href="/">トップ</a>,
    },
    {
      title: '送信箱',
    },
  ];

  return GlobalLayout(content(), breadcrumbList);
}

export default SentMessageListPage;
