import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  FolderAddOutlined,
  ImportOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Select,
  Space,
  notification,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import { YakInput } from '../../../components/YakInput';
import {
  AddressTypeLabel,
  AgencyAgreementType,
  AgencyAgreementTypeLabel,
  AllowFlagLabel,
  CalculateFormula,
  CalculateFormulaLabel,
  ContactLocationType,
  ContactLocationTypeLabel,
  ContactMethodType,
  ContractMethodTypeLabel,
  ContractStatus,
  CustomerTitleType,
  CustomerTitleTypeLabel,
  DraftingOfImportantMatterExplanation,
  DraftingOfImportantMatterExplanationLabel,
  FeePaymentMethodType,
  FeePaymentMethodTypeLabel,
  FinancingBankList,
  FundingMethodType,
  FundingMethodTypeLabel,
  ImportantMatterExplainerLabel,
  JudicialScrivenerAppointingLabel,
  OmittedInBetweenType,
  OmittedInBetweenTypeLabel,
  OverseasMoneyTransferType,
  OverseasMoneyTransferTypeLabel,
  OverseasRemittanceType,
  OverseasRemittanceTypeLabel,
  PropertyInHouseAdvertisingPlatformLabel,
  PurchasePurposeTypeLabel,
  ReinsAdvertisingTypeLabel,
  RemainingItemsRemovalCostResponsibilityTypeLabel,
  RemainingItemsRemovalDisposerTypeLabel,
  SalePropertyType,
  SalePropertyTypeLabel,
  SalePropertyVacancyStatusLabel,
  SaleTransactionMode,
  SaleTransactionModeLabel,
  SaleTransactionStatus,
  SaleTransactionStatusLabel,
  SaleViewingMethod,
  SaleViewingMethodLabel,
  StampDutyPreparationLabel,
  StampDutyResponsibilityLabel,
  SubmitStatus,
  TitleType,
  TitleTypeLabel,
  TransactionType,
  YesNoFlag,
  YesNoFlagLabel,
} from '../../../constants';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { useAuth } from '../../../hooks/useAuth';
import authHttpInteractor from '../../../interactors/authHttpInteractor';
import { CommonUtility } from '../../../utilities/CommonUtility';

const { Option } = Select;

export function InputSaleProperty({ salesEmployeeList }) {
  const { updateErrorMessage } = useContext(ErrorContext);
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const { getJwtPayload } = useAuth();
  const [form] = Form.useForm();

  // 案件情報
  const [propertyRecord, setPropertyRecord] = useState();
  // 契約開始ボタンの制御
  const [isStartContract, setIsStartContract] = useState(false);

  // 取引態様
  const currentTransactionMode = Form.useWatch('transactionMode', form);

  // 基本情報関連
  const currentInHouseAdvertising = Form.useWatch('inHouseAdvertising', form);
  const currentPropertyType = Form.useWatch('propertyType', form);
  const currentViewingMethod = Form.useWatch('viewingMethod', form);

  // 【契約情報】買主関連
  const currentBuyerTitleType = Form.useWatch('buyerTitleType', form);
  const currentBuyerFundingMethod = Form.useWatch('buyerFundingMethod', form);
  const currentBuyerFinancingBank = Form.useWatch('buyerFinancingBank', form);
  const currentBuyerCustomerTitleType = Form.useWatch('buyerCustomerTitleType', form);
  const currentBuyerCustomerTitleList = Form.useWatch('buyerCustomerTitleList', form);

  // 買主に個人がいるか
  const isIndividualCustomerInBuyer =
    currentBuyerCustomerTitleType === CustomerTitleType.INDIVIDUAL_CUSTOMER.toString() ||
    (currentBuyerCustomerTitleList &&
      currentBuyerCustomerTitleList.some(
        v => v && v.buyerCustomerTitleType === CustomerTitleType.INDIVIDUAL_CUSTOMER.toString(),
      ));

  // 【契約情報】売主関連
  const currentSellerTitleType = Form.useWatch('sellerTitleType', form);
  const currentSellerCustomerTitleType = Form.useWatch('sellerCustomerTitleType', form);
  const currentSellerCustomerTitleList = Form.useWatch('sellerCustomerTitleList', form);

  // 売主に個人がいるか
  const isIndividualCustomerInSeller =
    currentSellerCustomerTitleType === CustomerTitleType.INDIVIDUAL_CUSTOMER.toString() ||
    (currentSellerCustomerTitleList &&
      currentSellerCustomerTitleList.some(
        v => v && v.sellerCustomerTitleType === CustomerTitleType.INDIVIDUAL_CUSTOMER.toString(),
      ));

  // 【契約情報】物件情報関連
  const currentDisclosureProperty = Form.useWatch('disclosureProperty', form);
  const currentHandlingRemainingItems = Form.useWatch('handlingRemainingItems', form);
  const currentNewImportantMatterInvestigationReport = Form.useWatch(
    'newImportantMatterInvestigationReport',
    form,
  );

  // 【契約情報】契約情報関連
  const currentPriceTaxType = Form.useWatch('priceTaxType', form);
  const currentOmittedInBetweenType = Form.useWatch('omittedInBetweenType', form);
  const currentContractMethod = Form.useWatch('contractMethod', form);
  const currentContractLocation = Form.useWatch('contractLocation', form);
  const currentAgencyAgreement = Form.useWatch('agencyAgreement', form);
  const currentOverseasMoneyTransferType = Form.useWatch('overseasMoneyTransferType', form);
  const currentOverseasRemittanceType = Form.useWatch('overseasRemittanceType', form);

  const currentPaymentOfIntroductionFeeCommissionConsultingFee = Form.useWatch(
    'paymentOfIntroductionFeeCommissionConsultingFee',
    form,
  );
  const isIntroductionFeeChecked = Form.useWatch('introductionFeeCheckbox', form);
  const isCommissionFeeChecked = Form.useWatch('commissionFeeCheckbox', form);
  const isConsultingFeeChecked = Form.useWatch('consultingFeeCheckbox', form);
  const isBrokerageFeeChecked = Form.useWatch('brokerageFeeCheckbox', form);
  const isOtherFeeChecked = Form.useWatch('otherFeeCheckbox', form);

  const currentBrokerageFeeBuyer = Form.useWatch('brokerageFeeBuyer', form);
  const currentBrokerageFeeBuyerCalculateMethod = Form.useWatch(
    'brokerageFeeBuyerCalculateMethod',
    form,
  );

  const currentCommissionFeeBuyer = Form.useWatch('commissionFeeBuyer', form);
  const currentCommissionFeeBuyerCalculateMethod = Form.useWatch(
    'commissionFeeBuyerCalculateMethod',
    form,
  );

  const currentBrokerageFeeSeller = Form.useWatch('brokerageFeeSeller', form);
  const currentBrokerageFeeSellerCalculateMethod = Form.useWatch(
    'brokerageFeeSellerCalculateMethod',
    form,
  );

  const currentCommissionFeeSeller = Form.useWatch('commissionFeeSeller', form);
  const currentCommissionFeeSellerCalculateMethod = Form.useWatch(
    'commissionFeeSellerCalculateMethod',
    form,
  );
  const currentDraftingOfImportantMatterExplanation = Form.useWatch(
    'draftingOfImportantMatterExplanation',
    form,
  );

  // 契約開始しましたかの boolean を返す
  // 契約に進むボタンを押された、もしくは「客付け」の場合（お客つけの場合は自動的に契約開始となる）
  // 契約開始後
  //  ・自社広告掲載にある取引状態は、取引完了になり＆変更不可
  const isContractStarted =
    isStartContract || currentTransactionMode === SaleTransactionMode.PLACEMENT.toString();
  useEffect(() => {
    form.setFieldValue(
      'transactionStatus',
      isContractStarted
        ? SaleTransactionStatus.TRANSACTION_COMPLETED.toString()
        : SaleTransactionStatus.IN_PROGRESS.toString(),
    );
  }, [isContractStarted]);

  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  // ドラフト関連処理
  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, placement, desc) => {
    api[type]({
      message: `ドラフト`,
      description: desc,
      placement,
    });
  };

  const [savedDraft, setSavedDraft] = useState(null);
  const onSaveDraft = () => {
    const saveData = JSON.stringify({
      propertyId: null,
      savedTime: new Date().getTime(),
      data: form.getFieldsValue(),
    });
    // フォームの入力がある場合だけ保存
    if (Object.keys(JSON.parse(saveData).data).length > 0) {
      localStorage.setItem('sale_draft', saveData);
      openNotification('success', 'top', 'ドラフトを正しく保存されました。');
    } else {
      openNotification('warning', 'top', '保存対象がありませんでした。');
    }
  };
  const onImportDraft = () => {
    if (savedDraft) {
      form.setFieldsValue(savedDraft);
      openNotification('success', 'top', 'ドラフトを正しくインポートされました。');
      // 一度インポートされたら読込ボタンが非表示にする
      setSavedDraft(null);
    } else {
      openNotification('warning', 'top', 'インポート対象がありませんでした。');
    }
  };

  useEffect(() => {
    const draftData = localStorage.getItem('sale_draft');
    if (draftData) {
      try {
        const parsedDraft = JSON.parse(draftData);
        // parsedDraft.propertyId が null のは新規画面用です。
        // ドラフトの有効期限は１週間です。
        setSavedDraft(
          parsedDraft.propertyId ||
            dayjs(parsedDraft.savedTime).add(7, 'day').isBefore(new Date().getTime())
            ? null
            : parsedDraft.data,
        );
      } catch (e) {
        console.log(e);
        setSavedDraft(null);
      }
    }
  }, []);

  const DraftButtons = useMemo(
    () => (
      <Space
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          border: 'dashed 1px #e7e7e7',
          borderRadius: 5,
          marginBottom: 20,
          padding: 10,
        }}
      >
        {contextHolder}
        <Button
          type="dashed"
          style={{ borderColor: '#4096ff', color: '#4096ff' }}
          icon={<SaveOutlined />}
          onClick={() => {
            onSaveDraft();
          }}
        >
          ドラフトを保存
        </Button>
        {savedDraft && (
          <Button
            icon={<ImportOutlined />}
            danger
            onClick={() => {
              onImportDraft();
            }}
          >
            ドラフトを読込
          </Button>
        )}
      </Space>
    ),
    [savedDraft],
  );
  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
  // ドラフト関連処理
  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  /**
   * 売買代金
   *
   * 【新規の場合】
   * 客付けの場合、契約情報グループに表示
   * 元付・両手の場合、案件基本項目に表示
   *
   * 【編集の場合】
   * 契約開始前：案件基本項目に表示
   * 契約開始後：契約情報グループに表示
   *
   */
  const PropertyPriceField = useMemo(
    () => (
      <Form.Item
        name="price"
        label="売買代金(円)"
        hasFeedback
        style={{ marginBottom: 30 }}
        rules={[
          { required: true, message: '売買代金を入力してください' },
          {
            pattern: /^[1-9][0-9]*$/,
            message: '売買代金の形式が正しくありません',
          },
        ]}
      >
        <Input size="large" maxLength={20} placeholder="例：68000000" />
      </Form.Item>
    ),
    [],
  );

  // 基本情報Fields
  const BaseInfoFields = useMemo(
    () => (
      <>
        <Form.Item
          name="mainContactPerson"
          label="メイン担当者"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: 'メイン担当者を選択してください' }]}
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            placeholder="メイン担当者を選択してください"
            filterOption={(input, option) =>
              CommonUtility.removeAllSpace((option?.label ?? '').toLowerCase()).includes(
                CommonUtility.removeAllSpace(input.toLowerCase()),
              )
            }
            options={salesEmployeeList}
          />
        </Form.Item>
        <Form.Item
          name="contactPersonList"
          label="担当者"
          hasFeedback
          style={{ marginBottom: 30 }}
          // メイン担当が変わったらバリデーションを行う
          dependencies={['mainContactPerson']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, values) {
                // メイン担当者として選択されたら、担当者として選択不可
                if (
                  values &&
                  getFieldValue('mainContactPerson') &&
                  values.includes(getFieldValue('mainContactPerson'))
                ) {
                  return Promise.reject(new Error('メイン担当者以外の方を選択してください'));
                }

                // 最大５人まで選択できる
                if (values && values.length > 5) {
                  return Promise.reject(new Error('５人以下選択してください'));
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            size="large"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            placeholder="担当者を選択してください"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().trim().includes(input.toLowerCase().trim())
            }
            options={salesEmployeeList}
          />
        </Form.Item>

        <Form.Item
          name="propertyType"
          label="物件種別"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '物件種別を選択してください' }]}
        >
          <Select size="large" placeholder="物件種別を選択してください">
            {Object.keys(SalePropertyTypeLabel).map(type => (
              <Option key={type} value={type}>
                {SalePropertyTypeLabel[type]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="vacancyStatus"
          label="物件状況"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '物件状況を選択してください' }]}
        >
          <Select size="large" placeholder="物件状況を選択してください">
            {Object.keys(SalePropertyVacancyStatusLabel).map(status => (
              <Option key={status} value={status}>
                {SalePropertyVacancyStatusLabel[status]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="物件名"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            { required: true, message: '物件名を入力してください' },
            {
              max: 100,
              message: '物件名は100文字以内で入力してください',
            },
          ]}
        >
          <YakInput
            setval={value => {
              form.setFieldValue('name', value);
            }}
            size="large"
            maxLength={100}
            placeholder="例：岩本ビル"
          />
        </Form.Item>
        {[SalePropertyType.CONDOMINIUM.toString()].includes(currentPropertyType) && (
          <Form.Item
            name="roomNumber"
            label="部屋番号"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[
              { required: true, message: '部屋番号を入力してください' },
              {
                max: 100,
                message: '部屋番号は100文字以内で入力してください',
              },
            ]}
          >
            <YakInput
              setval={value => {
                form.setFieldValue('roomNumber', value);
              }}
              size="large"
              maxLength={100}
              placeholder="例：３０２号室"
            />
          </Form.Item>
        )}
        <Form.Item
          name="address"
          label="住居表示"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            { required: true, message: '住居表示を入力してください' },
            {
              max: 100,
              message: '住居表示は100文字以内で入力してください',
            },
          ]}
        >
          <YakInput
            setval={value => {
              form.setFieldValue('address', value);
            }}
            size="large"
            maxLength={100}
            placeholder="例：東京都台東区上野5-8-8 YAK御徒町ビル"
          />
        </Form.Item>

        {[
          SaleTransactionMode.SOURCING.toString(),
          SaleTransactionMode.DUAL_AGENCY.toString(),
        ].includes(currentTransactionMode) && PropertyPriceField}

        {/* 客付けの場合、自社広告掲載及び関連項目は不要 */}
        {currentTransactionMode !== SaleTransactionMode.PLACEMENT.toString() && (
          <>
            <Form.Item
              name="inHouseAdvertising"
              label="自社広告掲載"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '自社広告掲載を選択してください' }]}
            >
              <Radio.Group size="large">
                {Object.keys(YesNoFlagLabel).map(type => (
                  <Radio value={type} key={type}>
                    {YesNoFlagLabel[type]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            {currentInHouseAdvertising === YesNoFlag.YES.toString() && (
              <>
                <Form.Item
                  name="reinsAdvertising"
                  label="レインズ等の登録"
                  hasFeedback
                  style={{
                    marginBottom: 30,
                  }}
                  rules={[{ required: true, message: 'レインズ等の登録を選択してください' }]}
                >
                  <Select
                    size="large"
                    placeholder="レインズ等の登録を選択してください"
                    onChange={() => {
                      // XXX レインズ等の登録 変わったときに、広告プラットフォームがクリア必要
                      form.setFieldValue('inHouseAdvertisingPlatformList', []);
                    }}
                  >
                    {Object.keys(ReinsAdvertisingTypeLabel).map(type => (
                      <Option key={type} value={type}>
                        {ReinsAdvertisingTypeLabel[type]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="inHouseAdvertisingPlatformList"
                  label="広告プラットフォーム"
                  hasFeedback
                  style={{
                    marginBottom: 30,
                  }}
                  rules={[
                    { required: true, message: '広告プラットフォームをチェックしてください' },
                  ]}
                >
                  <Checkbox.Group
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 10,
                    }}
                    size="large"
                    options={Object.keys(PropertyInHouseAdvertisingPlatformLabel).map(platform => ({
                      label: PropertyInHouseAdvertisingPlatformLabel[platform],
                      value: platform,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  name="transactionStatus"
                  label="取引状態"
                  initialValue={SaleTransactionStatus.IN_PROGRESS.toString()}
                  hasFeedback
                  style={{ marginBottom: 30 }}
                  rules={[{ required: true, message: '取引状態を選択してください' }]}
                >
                  {/* XXX 新規登録の場合は 取引中 で固定、契約開始後は取引完了に変わる */}
                  <Select size="large" disabled placeholder="取引状態を選択してください">
                    {Object.keys(SaleTransactionStatusLabel).map(status => (
                      <Option key={status} value={status}>
                        {SaleTransactionStatusLabel[status]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="viewingMethod"
                  label="内見方法"
                  hasFeedback
                  style={{ marginBottom: 30 }}
                  rules={[{ required: true, message: '内見方法を選択してください' }]}
                >
                  <Select size="large" placeholder="内見方法を選択してください">
                    {Object.keys(SaleViewingMethodLabel).map(method => (
                      <Option key={method} value={method}>
                        {SaleViewingMethodLabel[method]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {currentViewingMethod === SaleViewingMethod.KEY_LENDING_AT_THE_STORE.toString() && (
                  <>
                    <Form.Item
                      name="entrustedPartyName"
                      label="預り業者名"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '預り業者名を入力してください' },
                        {
                          max: 100,
                          message: '預り業者名は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('entrustedPartyName', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="entrustedPartyLocation"
                      label="預り業者場所"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '預り業者場所を入力してください' },
                        {
                          max: 100,
                          message: '預り業者場所は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('entrustedPartyLocation', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="entrustedPartyContactMethod"
                      label="預り業者連絡方法"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '預り業者連絡方法を入力してください' },
                        {
                          max: 100,
                          message: '預り業者連絡方法は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('entrustedPartyContactMethod', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                  </>
                )}

                {currentViewingMethod === SaleViewingMethod.NO_VIEWING.toString() && (
                  <>
                    <Form.Item
                      name="viewingNotPossibleReason"
                      label="不可の原因"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '不可の原因を入力してください' },
                        {
                          max: 100,
                          message: '不可の原因は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('viewingNotPossibleReason', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="viewingPossibleTime"
                      label="内見可能となる時間"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '内見可能となる時間を入力してください' },
                        {
                          max: 100,
                          message: '内見可能となる時間は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('viewingPossibleTime', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                  </>
                )}

                {currentViewingMethod === SaleViewingMethod.ON_SITE_SUPPORT.toString() && (
                  <>
                    <Form.Item
                      name="keyBoxLocation"
                      label="キーボックスの保存場所"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: 'キーボックスの保存場所を入力してください' },
                        {
                          max: 100,
                          message: 'キーボックスの保存場所は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('keyBoxLocation', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="keyBoxPassword"
                      label="キーボックスのパスワード"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: 'キーボックスのパスワードを入力してください' },
                        {
                          max: 100,
                          message: 'キーボックスのパスワードは100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('keyBoxPassword', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  name="thirdPartyAdvertising"
                  label="他社広告掲載"
                  hasFeedback
                  style={{ marginBottom: 30 }}
                  rules={[{ required: true, message: '他社広告掲載を選択してください' }]}
                >
                  <Radio.Group size="large">
                    {Object.keys(AllowFlagLabel).map(type => (
                      <Radio value={type} key={type}>
                        {AllowFlagLabel[type]}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </>
        )}
      </>
    ),
    [
      PropertyPriceField,
      currentInHouseAdvertising,
      currentPropertyType,
      currentTransactionMode,
      currentViewingMethod,
      form,
      salesEmployeeList,
    ],
  );

  // 【契約情報】買主Fields
  const ContractBuyerFields = useMemo(
    () => (
      <>
        <Form.Item
          name="buyerTitleType"
          label="買主名称"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '買主名称を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(TitleTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {TitleTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={
            <>
              <span style={{ color: '#ff4d4f', fontSize: 14, marginRight: 3 }}>*</span>買主名義
            </>
          }
          style={{
            marginBottom: 30,
          }}
        >
          <Form.Item
            name="buyerCustomerTitleType"
            hasFeedback
            style={{
              display: 'inline-block',
              width: '30%',
              marginRight: 10,
              marginBottom: 0,
            }}
            rules={[
              {
                required: true,
                message: '買主名義種類を選択してください',
              },
            ]}
          >
            <Select size="large" placeholder="買主名義種類を選択してください">
              {Object.keys(CustomerTitleTypeLabel).map(type => (
                <Option key={type} value={type}>
                  {CustomerTitleTypeLabel[type]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="buyerCustomerTitle"
            hasFeedback
            style={{
              display: 'inline-block',
              width: '60%',
              marginBottom: 0,
            }}
            rules={[
              { required: true, message: '買主名義を入力してください' },
              {
                max: 100,
                message: '買主名義は100文字以内で入力してください',
              },
            ]}
          >
            <Input size="large" maxLength={100} placeholder="例：田中 太郎" />
          </Form.Item>
        </Form.Item>

        {currentBuyerTitleType === TitleType.JOINT.toString() && (
          <Form.List name="buyerCustomerTitleList">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Form.Item label="買主名義" required>
                      <Form.Item
                        name={[name, 'buyerCustomerTitleType']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: '買主名義種類を選択してください',
                          },
                        ]}
                        noStyle
                      >
                        <Select
                          size="large"
                          style={{
                            width: '30%',
                            marginRight: 10,
                          }}
                          placeholder="買主名義種類を選択してください"
                        >
                          {Object.keys(CustomerTitleTypeLabel).map(type => (
                            <Option key={type} value={type}>
                              {CustomerTitleTypeLabel[type]}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'buyerCustomerTitle']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: '買主名義を入力してください',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          size="large"
                          maxLength={20}
                          placeholder="例：田中 太郎"
                          style={{
                            width: '60%',
                          }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ marginLeft: 10, fontSize: 20 }}
                        onClick={() => remove(name)}
                      />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Form.ErrorList errors={errors} />
                  {fields.length < 9 && (
                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      買主名義入力欄を追加
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form.List>
        )}

        <Form.Item
          name="buyerAddressType"
          label="買主住所"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '買主住所を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(AddressTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {AddressTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="buyerAddress"
          label="買主住所詳細"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              // XXX 元付けの買主の住所詳細は任意
              required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
              message: '買主住所詳細を入力してください',
            },
            {
              max: 100,
              message: '買主住所詳細は100文字以内で入力してください',
            },
          ]}
        >
          <Input size="large" maxLength={100} placeholder="例：東京都新宿区" />
        </Form.Item>

        <Form.Item
          name="buyerPhoneNumber"
          label="買主TEL"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              // XXX 元付けの買主のTELは任意
              required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
              message: '買主TELを入力してください',
            },
            // {
            //   max: 11,
            //   message: '買主TELは11文字以内で入力してください',
            // },
            // {
            //   pattern: /^0\d{9,10}$/,
            //   message: '買主TELの形式が正しくありません',
            // },
          ]}
        >
          <YakInput
            setval={value => {
              form.setFieldValue('buyerPhoneNumber', value);
            }}
            size="large"
            maxLength={11}
            placeholder="例：0358175817"
          />
        </Form.Item>

        <Form.Item
          name="buyerPurchasePurpose"
          label="購入目的"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '購入目的を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(PurchasePurposeTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {PurchasePurposeTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="buyerFundingMethod"
          label="資金調達手段"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '資金調達手段を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(FundingMethodTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {FundingMethodTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {/* 元付けの場合、融資関連項目は全部optional */}
        {[
          FundingMethodType.HOUSING_LOAN.toString(),
          FundingMethodType.INVESTMENT_LOAN.toString(),
        ].includes(currentBuyerFundingMethod) && (
          <>
            <Form.Item
              name="buyerFinancingBank"
              label="銀行名称"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
                  message: '銀行名称を選択してください',
                },
              ]}
            >
              <Select size="large" placeholder="銀行名称を選択してください">
                {Object.keys(FinancingBankList).map(bankCode => (
                  <Option key={bankCode} value={bankCode}>
                    {FinancingBankList[bankCode]}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* 銀行をその他を選択された場合、銀行名を入力必要 */}
            {currentBuyerFinancingBank === '26' && (
              <Form.Item
                name="buyerFinancingBankFree"
                label="その他銀行名"
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '銀行名称を入力してください',
                  },
                  {
                    max: 100,
                    message: '銀行名は100文字以内で入力してください',
                  },
                ]}
              >
                <Input size="large" maxLength={100} placeholder="例：横浜銀行" />
              </Form.Item>
            )}

            <Form.Item
              name="buyerBorrowingBranchName"
              label="借入支店名"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  max: 100,
                  message: '借入支店名は100文字以内で入力してください',
                },
              ]}
            >
              <Input size="large" maxLength={100} placeholder="例：新宿支店" />
            </Form.Item>
            <Form.Item
              name="buyerFinancingBankContactName"
              label="融資銀行の担当者名"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  max: 100,
                  message: '融資銀行の担当者名は100文字以内で入力してください',
                },
              ]}
            >
              <Input size="large" maxLength={100} placeholder="例：銀行 太郎" />
            </Form.Item>
            <Form.Item
              name="buyerFinancingAmount"
              label="融資金額"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
                  message: '融資金額を入力してください',
                },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '融資金額の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：68000000" />
            </Form.Item>
            <Form.Item
              name="buyerBorrowingInterestRate"
              label="借入金利"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
                  message: '借入金利を入力してください',
                },
                // {
                //   pattern: /^\d+(\.\d+)?$/,
                //   message: '借入金利の形式が正しくありません',
                // },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：0.51" />
            </Form.Item>

            <Form.Item
              name="buyerBorrowingTerm"
              label="借入年限"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
                  message: '借入年限を入力してください',
                },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '借入年限の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={2} placeholder="例：30" />
            </Form.Item>

            <Form.Item
              name="buyerFinancingSpecialAgreementTerminationDate"
              label="融資特約解除予定日"
              hasFeedback
              style={{
                marginBottom: 30,
              }}
              rules={[
                {
                  required: currentTransactionMode !== SaleTransactionMode.SOURCING.toString(),
                  message: '融資特約解除予定日を選択してください',
                },
              ]}
            >
              <DatePicker format="YYYY/MM/DD" size="large" />
            </Form.Item>
          </>
        )}

        {/* XXX 現時点フロント側のみの項目のため、BEに渡さない */}
        {/* 顧客に個人がいる場合のみ表示 */}
        {isIndividualCustomerInBuyer &&
          [
            SaleTransactionMode.PLACEMENT.toString(),
            SaleTransactionMode.DUAL_AGENCY.toString(),
          ].includes(currentTransactionMode) && (
            <Form.Item
              name="agreeToCollectBuyerPersonalInfo"
              label="個人情報同意書の取得"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: true,
                  message: '個人情報同意書の取得を選択してください',
                },
                {
                  validator: (_, value) =>
                    !value || value === YesNoFlag.YES.toString()
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error('個人情報同意書の取得が「有」の場合だけ登録できます。'),
                        ),
                },
              ]}
            >
              <Radio.Group size="large">
                {Object.keys(YesNoFlagLabel).map(type => (
                  <Radio value={type} key={type}>
                    {YesNoFlagLabel[type]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          )}
      </>
    ),
    [
      currentBuyerFinancingBank,
      currentBuyerFundingMethod,
      currentBuyerTitleType,
      currentTransactionMode,
      form,
      isIndividualCustomerInBuyer,
    ],
  );

  // 【契約情報】売主Fields
  const ContractSellerFields = useMemo(
    () => (
      <>
        <Form.Item
          name="sellerTitleType"
          label="売主名称"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '売主名称を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(TitleTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {TitleTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={
            <>
              <span style={{ color: '#ff4d4f', fontSize: 14, marginRight: 3 }}>*</span>売主名義
            </>
          }
          style={{
            marginBottom: 30,
          }}
        >
          <Form.Item
            name="sellerCustomerTitleType"
            hasFeedback
            style={{
              display: 'inline-block',
              width: '30%',
              marginRight: 10,
              marginBottom: 0,
            }}
            rules={[
              {
                required: true,
                message: '売主名義種類を選択してください',
              },
            ]}
          >
            <Select size="large" placeholder="売主名義種類を選択してください">
              {Object.keys(CustomerTitleTypeLabel).map(type => (
                <Option key={type} value={type}>
                  {CustomerTitleTypeLabel[type]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="sellerCustomerTitle"
            hasFeedback
            style={{
              display: 'inline-block',
              width: '60%',
              marginBottom: 0,
            }}
            rules={[
              { required: true, message: '売主名義を入力してください' },
              {
                max: 100,
                message: '売主名義は100文字以内で入力してください',
              },
            ]}
          >
            <Input size="large" maxLength={100} placeholder="例：田中 太郎" />
          </Form.Item>
        </Form.Item>

        {currentSellerTitleType === TitleType.JOINT.toString() && (
          <Form.List name="sellerCustomerTitleList">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Form.Item label="売主名義" required>
                      <Form.Item
                        name={[name, 'sellerCustomerTitleType']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: '売主名義種類を選択してください',
                          },
                        ]}
                        noStyle
                      >
                        <Select
                          size="large"
                          style={{
                            width: '30%',
                            marginRight: 10,
                          }}
                          placeholder="売主名義種類を選択してください"
                        >
                          {Object.keys(CustomerTitleTypeLabel).map(type => (
                            <Option key={type} value={type}>
                              {CustomerTitleTypeLabel[type]}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'sellerCustomerTitle']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: '売主名義を入力してください',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          size="large"
                          maxLength={20}
                          placeholder="例：田中 太郎"
                          style={{
                            width: '60%',
                          }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ marginLeft: 10, fontSize: 20 }}
                        onClick={() => remove(name)}
                      />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Form.ErrorList errors={errors} />
                  {fields.length < 9 && (
                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      売主名義入力欄を追加
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form.List>
        )}

        <Form.Item
          name="sellerAddressType"
          label="売主住所"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '売主住所を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(AddressTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {AddressTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="sellerAddress"
          label="売主住所詳細"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              // XXX 客付けの売主の住所詳細は任意
              required: currentTransactionMode !== SaleTransactionMode.PLACEMENT.toString(),
              message: '売主住所詳細を入力してください',
            },
            {
              max: 100,
              message: '売主住所詳細は100文字以内で入力してください',
            },
          ]}
        >
          <Input size="large" maxLength={100} placeholder="例：東京都新宿区" />
        </Form.Item>

        <Form.Item
          name="sellerPhoneNumber"
          label="売主TEL"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              // XXX 客付けの売主のTELは任意
              required: currentTransactionMode !== SaleTransactionMode.PLACEMENT.toString(),
              message: '売主TELを入力してください',
            },
            // {
            //   max: 11,
            //   message: '売主TELは11文字以内で入力してください',
            // },
            // {
            //   pattern: /^0\d{9,10}$/,
            //   message: '売主TELの形式が正しくありません',
            // },
          ]}
        >
          <YakInput
            setval={value => {
              form.setFieldValue('sellerPhoneNumber', value);
            }}
            size="large"
            maxLength={11}
            placeholder="例：0358175817"
          />
        </Form.Item>

        {/* XXX 現時点フロント側のみの項目のため、BEに渡さない */}
        {/* 顧客に個人がいる場合のみ表示 */}
        {isIndividualCustomerInSeller &&
          [
            SaleTransactionMode.SOURCING.toString(),
            SaleTransactionMode.DUAL_AGENCY.toString(),
          ].includes(currentTransactionMode) && (
            <>
              <Form.Item
                name="agreeToCollectBrokerageContract"
                label="媒介契約書の取得"
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '媒介契約書の取得を選択してください',
                  },
                  {
                    validator: (_, value) =>
                      !value || value === YesNoFlag.YES.toString()
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error('媒介契約書の取得が「有」の場合だけ登録できます。'),
                          ),
                  },
                ]}
              >
                <Radio.Group size="large">
                  {Object.keys(YesNoFlagLabel).map(type => (
                    <Radio value={type} key={type}>
                      {YesNoFlagLabel[type]}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="agreeToCollectSellerPersonalInfo"
                label="個人情報同意書の取得"
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '個人情報同意書の取得を選択してください',
                  },
                  {
                    validator: (_, value) =>
                      !value || value === YesNoFlag.YES.toString()
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error('個人情報同意書の取得が「有」の場合だけ登録できます。'),
                          ),
                  },
                ]}
              >
                <Radio.Group size="large">
                  {Object.keys(YesNoFlagLabel).map(type => (
                    <Radio value={type} key={type}>
                      {YesNoFlagLabel[type]}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </>
          )}
      </>
    ),
    [currentSellerTitleType, currentTransactionMode, form, isIndividualCustomerInSeller],
  );

  // 【契約情報】物件情報Fields
  const ContractPropertyFields = useMemo(
    () => (
      <>
        <Form.Item
          name="recordOfCertifiedCopy"
          label="自社での謄本取得履歴"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '自社での謄本取得履歴を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(YesNoFlagLabel).map(flag => (
              <Radio value={flag} key={flag}>
                {YesNoFlagLabel[flag]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="disclosureProperty"
          label="告知物件"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '告知物件を選択してください' }]}
        >
          <Radio.Group size="large">
            <Radio value={YesNoFlag.YES} key={YesNoFlag.YES}>
              該当する
            </Radio>
            <Radio value={YesNoFlag.NO} key={YesNoFlag.NO}>
              該当しない
            </Radio>
          </Radio.Group>
        </Form.Item>

        {currentDisclosureProperty === YesNoFlag.YES && (
          <Form.Item
            name="disclosurePropertyMemo"
            label="告知物件詳細"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[
              { required: true, message: '告知物件詳細を入力してください' },
              {
                max: 100,
                message: '告知物件詳細は100文字以内で入力してください',
              },
            ]}
          >
            <YakInput
              setval={value => {
                form.setFieldValue('disclosurePropertyMemo', value);
              }}
              size="large"
              maxLength={100}
            />
          </Form.Item>
        )}

        <Form.Item
          name="handlingRemainingItems"
          label="残置物処理"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '残置物処理を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(YesNoFlagLabel).map(flag => (
              <Radio value={flag} key={flag}>
                {YesNoFlagLabel[flag]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentHandlingRemainingItems === YesNoFlag.YES.toString() && (
          <>
            <Form.Item
              name="remainingItemsRemovalDisposer"
              label="残置物撤去処分"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '残置物撤去処分を選択してください' }]}
            >
              <Radio.Group size="large">
                {Object.keys(RemainingItemsRemovalDisposerTypeLabel).map(type => (
                  <Radio value={type} key={type}>
                    {RemainingItemsRemovalDisposerTypeLabel[type]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="remainingItemsRemovalCostResponsibility"
              label="残置物撤去費用負担"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '残置物撤去費用負担を選択してください' }]}
            >
              <Radio.Group size="large">
                {Object.keys(RemainingItemsRemovalCostResponsibilityTypeLabel).map(type => (
                  <Radio value={type} key={type}>
                    {RemainingItemsRemovalCostResponsibilityTypeLabel[type]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </>
        )}

        <Form.Item
          name="contractNonconformity"
          label="契約不適合"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '契約不適合を選択してください' }]}
        >
          <Radio.Group size="large">
            <Radio value={YesNoFlag.YES} key={YesNoFlag.YES}>
              負う
            </Radio>
            <Radio value={YesNoFlag.NO} key={YesNoFlag.NO}>
              負わない
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="facilityDefectGuarantee"
          label="設備瑕疵担保"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '設備瑕疵担保を選択してください' }]}
        >
          <Radio.Group size="large">
            <Radio value={YesNoFlag.YES} key={YesNoFlag.YES}>
              負う
            </Radio>
            <Radio value={YesNoFlag.NO} key={YesNoFlag.NO}>
              負わない
            </Radio>
          </Radio.Group>
        </Form.Item>

        {[SalePropertyType.CONDOMINIUM.toString()].includes(currentPropertyType) && (
          <Form.Item
            name="newImportantMatterInvestigationReport"
            label="新重要事項調査報告書"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[{ required: true, message: '新重要事項調査報告書を選択してください' }]}
          >
            <Radio.Group size="large">
              <Radio value={YesNoFlag.YES} key={YesNoFlag.YES}>
                手配必要
              </Radio>
              <Radio value={YesNoFlag.NO} key={YesNoFlag.NO}>
                手配不要
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {currentNewImportantMatterInvestigationReport === YesNoFlag.NO && (
          <Form.Item
            name="nowImportantMatterInvestigationReportIssueDate"
            label="現調査報告書発行日"
            hasFeedback
            style={{
              marginBottom: 30,
            }}
            rules={[{ required: true, message: '現調査報告書発行日を選択してください' }]}
          >
            <DatePicker format="YYYY/MM/DD" size="large" />
          </Form.Item>
        )}

        <Form.Item
          name="reinsRegistrationDate"
          label="レインズに登録日"
          hasFeedback
          style={{
            marginBottom: 30,
          }}
        >
          <DatePicker format="YYYY/MM/DD" size="large" />
        </Form.Item>
      </>
    ),
    [
      currentDisclosureProperty,
      currentHandlingRemainingItems,
      currentNewImportantMatterInvestigationReport,
      currentPropertyType,
      form,
    ],
  );

  // 【契約情報】契約情報Fields
  const ContractInfoFields = useMemo(
    () => (
      <>
        {/* 【売主Fields】客付けの場合 */}
        {currentTransactionMode === SaleTransactionMode.PLACEMENT.toString() &&
          ContractSellerFields}

        {/* 【買主Fields】元付けの場合 */}
        {currentTransactionMode === SaleTransactionMode.SOURCING.toString() && ContractBuyerFields}

        {/* 新規の場合は、客付けの場合だけ 売買代金 が契約情報グループに */}
        {[SaleTransactionMode.PLACEMENT.toString()].includes(currentTransactionMode) &&
          PropertyPriceField}

        <Form.Item
          name="priceTaxType"
          label="建物消費税"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '建物消費税を選択してください' }]}
        >
          <Radio.Group size="large">
            <Radio value={YesNoFlag.YES} key={YesNoFlag.YES}>
              かかる
            </Radio>
            <Radio value={YesNoFlag.NO} key={YesNoFlag.NO}>
              かからない
            </Radio>
          </Radio.Group>
        </Form.Item>

        {currentPriceTaxType === YesNoFlag.YES && (
          <Form.Item
            name="taxAmount"
            label="消費税金額"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[
              // { required: true, message: '消費税金額を入力してください' },
              {
                pattern: /^[1-9][0-9]*$/,
                message: '消費税金額の形式が正しくありません',
              },
            ]}
          >
            <Input size="large" maxLength={20} placeholder="例：68000000" />
          </Form.Item>
        )}

        <Form.Item
          name="earnestMoneyAmount"
          label="手付金額"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            { required: true, message: '手付金額を入力してください' },
            {
              pattern: /^[1-9][0-9]*$/,
              message: '手付金額の形式が正しくありません',
            },
          ]}
        >
          <Input size="large" maxLength={20} placeholder="例：68000000" />
        </Form.Item>

        <Form.Item
          name="contractDate"
          label="契約日"
          hasFeedback
          style={{
            marginBottom: 30,
          }}
          rules={[{ required: true, message: '契約日を選択してください' }]}
        >
          <DatePicker
            format="YYYY/MM/DD HH:mm:ss"
            showTime={{
              format: 'HH:mm:ss',
            }}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="scheduledSettlementDate"
          label="決済予定日"
          hasFeedback
          style={{
            marginBottom: 30,
          }}
          rules={[{ required: true, message: '決済予定日を選択してください' }]}
        >
          <DatePicker format="YYYY/MM/DD" size="large" />
        </Form.Item>

        <Form.Item
          name="omittedInBetweenType"
          label="中間省略"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '中間省略を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(OmittedInBetweenTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {OmittedInBetweenTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentOmittedInBetweenType === OmittedInBetweenType.FREE.toString() && (
          <Form.Item
            name="omittedInBetweenName"
            label="中間省略名"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[
              { required: true, message: '中間省略名を入力してください' },
              {
                max: 100,
                message: '中間省略名は100文字以内で入力してください',
              },
            ]}
          >
            <YakInput
              setval={value => {
                form.setFieldValue('omittedInBetweenName', value);
              }}
              size="large"
              maxLength={100}
            />
          </Form.Item>
        )}

        <Form.Item
          name="contractMethod"
          label="契約方法"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '契約方法を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(ContractMethodTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {ContractMethodTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentContractMethod === ContactMethodType.ALTERNATING_CONTRACT.toString() && (
          <Form.Item
            name="contractMethodFixedTime"
            label="契約方法最終版確定日"
            hasFeedback
            style={{
              marginBottom: 30,
            }}
            rules={[{ required: true, message: '契約方法最終版確定日を選択してください' }]}
          >
            <DatePicker
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{
                format: 'HH:mm:ss',
              }}
              size="large"
            />
          </Form.Item>
        )}

        <Form.Item
          name="contractLocation"
          label="契約場所"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '契約場所を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(ContactLocationTypeLabel).map(type => (
              <Radio value={type} key={type}>
                {ContactLocationTypeLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentContractLocation === ContactLocationType.OTHER.toString() && (
          <Form.Item
            name="contractLocationMemo"
            label="契約場所備考"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[
              { required: true, message: '契約場所備考を入力してください' },
              {
                max: 100,
                message: '契約場所備考は100文字以内で入力してください',
              },
            ]}
          >
            <YakInput
              setval={value => {
                form.setFieldValue('contractLocationMemo', value);
              }}
              size="large"
              maxLength={100}
            />
          </Form.Item>
        )}

        <Form.Item
          name="importantMatterExplainer"
          label="重要事項説明者"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '重要事項説明者を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(ImportantMatterExplainerLabel).map(type => (
              <Radio value={type} key={type}>
                {ImportantMatterExplainerLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="inHouseRealEstateExpert"
          label="自社宅建士"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '自社宅建士を選択してください' }]}
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            placeholder="自社宅建士を選択してください"
            filterOption={(input, option) =>
              CommonUtility.removeAllSpace((option?.label ?? '').toLowerCase()).includes(
                CommonUtility.removeAllSpace(input.toLowerCase()),
              )
            }
            // TODO APIをコールしてリストを取得
            options={[
              { value: 1, label: '相手側宅建士' },
              { value: 2, label: '易 娟' },
              { value: 3, label: '白 雪' },
              { value: 4, label: '郭 艶紅' },
              { value: 5, label: '鄧 文平' },
              { value: 6, label: '盧 志恒' },
              { value: 7, label: '袁 文娟 ' },
              { value: 8, label: '叶 瑩瑩' },
              { value: 9, label: 'WU YUYING' },
              { value: 10, label: '厳 森' },
              { value: 11, label: '田辺 裕人' },
              { value: 12, label: '趙 芸紋' },
              { value: 13, label: '孫 恬' },
              { value: 14, label: '王 成濤' },
              { value: 15, label: '夏 玲玲' },
              { value: 16, label: '最上 龍' },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="draftingOfImportantMatterExplanation"
          label="重説作成者"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '重説作成者を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(DraftingOfImportantMatterExplanationLabel).map(type => (
              <Radio value={type} key={type}>
                {DraftingOfImportantMatterExplanationLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {currentDraftingOfImportantMatterExplanation ===
          DraftingOfImportantMatterExplanation.COUNTERPARTY_COMPANY.toString() && (
          <Form.Item
            name="draftingOfImportantMatterReceivedDate"
            label="ドラフトもらう時間"
            hasFeedback
            style={{
              marginBottom: 30,
            }}
          >
            <DatePicker
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{
                format: 'HH:mm:ss',
              }}
              size="large"
            />
          </Form.Item>
        )}

        <Form.Item
          name="agencyAgreement"
          label="代理契約"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '代理契約を選択してください' }]}
        >
          <Select size="large" placeholder="代理契約を選択してください">
            {Object.keys(AgencyAgreementTypeLabel).map(type => (
              <Option key={type} value={type}>
                {AgencyAgreementTypeLabel[type]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {currentAgencyAgreement === AgencyAgreementType.NOT_YET.toString() && (
          <Form.Item
            name="agencyAgreementDate"
            label="委任状取得日"
            hasFeedback
            style={{
              marginBottom: 30,
            }}
            rules={[{ required: true, message: '委任状取得日を選択してください' }]}
          >
            <DatePicker format="YYYY/MM/DD" size="large" />
          </Form.Item>
        )}

        <Form.Item
          name="overseasMoneyTransferType"
          label="海外入出金"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '海外入出金を選択してください' }]}
        >
          <Select size="large" placeholder="海外入出金を選択してください">
            {Object.keys(OverseasMoneyTransferTypeLabel).map(type => (
              <Option key={type} value={type}>
                {OverseasMoneyTransferTypeLabel[type]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {[
          OverseasMoneyTransferType.FROM_OVERSEAS_REMITTANCE.toString(),
          OverseasMoneyTransferType.BOTH_INCOMING_AND_OUTGOING_REMITTANCE.toString(),
        ].includes(currentOverseasMoneyTransferType) && (
          <>
            <Form.Item
              name="overseasRemittanceType"
              label="海外入金"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '海外入金を選択してください' }]}
            >
              <Radio.Group size="large">
                {Object.keys(OverseasRemittanceTypeLabel).map(type => (
                  <Radio value={type} key={type}>
                    {OverseasRemittanceTypeLabel[type]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {[
              OverseasRemittanceType.CHINA_NOT_MAIN_LAND.toString(),
              OverseasRemittanceType.NOT_CHINA.toString(),
            ].includes(currentOverseasRemittanceType) && (
              <Form.Item
                name="overseasRemittanceDetail"
                label={`その${
                  OverseasRemittanceType.CHINA_NOT_MAIN_LAND.toString() ===
                  currentOverseasRemittanceType
                    ? '省'
                    : '国'
                }名`}
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: `その${
                      OverseasRemittanceType.CHINA_NOT_MAIN_LAND.toString() ===
                      currentOverseasRemittanceType
                        ? '省'
                        : '国'
                    }名を入力してください`,
                  },
                  {
                    max: 100,
                    message: `その${
                      OverseasRemittanceType.CHINA_NOT_MAIN_LAND.toString() ===
                      currentOverseasRemittanceType
                        ? '省'
                        : '国'
                    }名は100文字以内で入力してください`,
                  },
                ]}
              >
                <YakInput
                  setval={value => {
                    form.setFieldValue('overseasRemittanceDetail', value);
                  }}
                  size="large"
                  maxLength={100}
                />
              </Form.Item>
            )}
          </>
        )}

        <Form.Item
          name="judicialScrivenerAppointing"
          label="司法書士指定"
          hasFeedback
          style={{ marginBottom: 30 }}
        >
          <Radio.Group size="large">
            {Object.keys(JudicialScrivenerAppointingLabel).map(type => (
              <Radio value={type} key={type}>
                {JudicialScrivenerAppointingLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="stampDutyResponsibility"
          label="印紙負担"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '印紙負担を選択してください' }]}
        >
          <Radio.Group size="large">
            {Object.keys(StampDutyResponsibilityLabel).map(type => (
              <Radio value={type} key={type}>
                {StampDutyResponsibilityLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="stampDutyPreparation"
          label="印紙準備"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[{ required: true, message: '印紙準備を選択してください' }]}
        >
          <Select size="large" placeholder="印紙準備を選択してください">
            {Object.keys(StampDutyPreparationLabel).map(type => (
              <Option key={type} value={type}>
                {StampDutyPreparationLabel[type]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="paymentOfIntroductionFeeCommissionConsultingFee"
          dependencies={[
            'introductionFeeCheckbox',
            'commissionFeeCheckbox',
            'consultingFeeCheckbox',
            'brokerageFeeCheckbox',
            'otherFeeCheckbox',
          ]}
          label={
            <span style={{ fontSize: 12 }}>
              紹介料、業務委託料
              <br />
              コンサルティング料等の支給
            </span>
          }
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              required: true,
              message: '紹介料、業務委託料、コンサルティング料等の支給を選択してください',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value === YesNoFlag.YES.toString()) {
                  if (
                    !getFieldValue('introductionFeeCheckbox') &&
                    !getFieldValue('commissionFeeCheckbox') &&
                    !getFieldValue('consultingFeeCheckbox') &&
                    !getFieldValue('brokerageFeeCheckbox') &&
                    !getFieldValue('otherFeeCheckbox')
                  ) {
                    return Promise.reject(
                      new Error(
                        '紹介料,業務委託料,コンサルティング料,仲介手数料,その他から一つ以上選択必要です。',
                      ),
                    );
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Radio.Group
            size="large"
            onChange={e => {
              if (e.target.value === YesNoFlag.NO.toString()) {
                form.setFieldValue('introductionFeeCheckbox', false);
                form.setFieldValue('commissionFeeCheckbox', false);
                form.setFieldValue('consultingFeeCheckbox', false);
                form.setFieldValue('brokerageFeeCheckbox', false);
                form.setFieldValue('otherFeeCheckbox', false);
              }
            }}
          >
            {Object.keys(YesNoFlagLabel).map(flag => (
              <Radio value={flag} key={flag}>
                {YesNoFlagLabel[flag]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentPaymentOfIntroductionFeeCommissionConsultingFee === YesNoFlag.YES.toString() && (
          <>
            <Form.Item label="紹介料" style={{ marginBottom: 30 }}>
              <Form.Item
                name="introductionFeeCheckbox"
                valuePropName="checked"
                style={{ display: 'inline-block', margin: '0 5px 0 0', width: 15 }}
                hasFeedback
              >
                <Checkbox style={{ padding: '5px 10px 5px 0' }} />
              </Form.Item>

              {isIntroductionFeeChecked && (
                <>
                  <Form.Item
                    name="introductionFeeAmount"
                    label="支払金額"
                    hasFeedback
                    style={{ display: 'inline-block', margin: '0 10px', width: 240 }}
                    rules={[
                      { required: true, message: '支払金額を入力してください' },
                      {
                        pattern: /^[1-9][0-9]*$/,
                        message: '支払金額の形式が正しくありません',
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder="例：6800" />
                  </Form.Item>

                  <Form.Item
                    name="introductionFeePaymentMethod"
                    label="支払方法"
                    hasFeedback
                    style={{ display: 'inline-block', margin: 0, width: 220 }}
                    rules={[{ required: true, message: '支払方法を選択してください' }]}
                  >
                    <Radio.Group>
                      {Object.keys(FeePaymentMethodTypeLabel).map(type => (
                        <Radio value={type} key={type}>
                          {FeePaymentMethodTypeLabel[type]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Form.Item>
            <Form.Item label="業務委託料" style={{ marginBottom: 30 }}>
              <Form.Item
                name="commissionFeeCheckbox"
                valuePropName="checked"
                style={{ display: 'inline-block', margin: '0 5px 0 0', width: 15 }}
                hasFeedback
              >
                <Checkbox style={{ padding: '5px 10px 5px 0' }} />
              </Form.Item>

              {isCommissionFeeChecked && (
                <>
                  <Form.Item
                    name="commissionFeeAmount"
                    label="支払金額"
                    hasFeedback
                    style={{ display: 'inline-block', margin: '0 10px', width: 240 }}
                    rules={[
                      { required: true, message: '支払金額を入力してください' },
                      {
                        pattern: /^[1-9][0-9]*$/,
                        message: '支払金額の形式が正しくありません',
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder="例：6800" />
                  </Form.Item>

                  <Form.Item
                    name="commissionFeePaymentMethod"
                    label="支払方法"
                    hasFeedback
                    style={{ display: 'inline-block', margin: 0, width: 220 }}
                    rules={[{ required: true, message: '支払方法を選択してください' }]}
                  >
                    <Radio.Group>
                      {Object.keys(FeePaymentMethodTypeLabel).map(type => (
                        <Radio value={type} key={type}>
                          {FeePaymentMethodTypeLabel[type]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Form.Item>
            <Form.Item label="コンサルティング料" style={{ marginBottom: 30 }}>
              <Form.Item
                name="consultingFeeCheckbox"
                valuePropName="checked"
                style={{ display: 'inline-block', margin: '0 5px 0 0', width: 15 }}
                hasFeedback
              >
                <Checkbox style={{ padding: '5px 10px 5px 0' }} />
              </Form.Item>

              {isConsultingFeeChecked && (
                <>
                  <Form.Item
                    name="consultingFeeAmount"
                    label="支払金額"
                    hasFeedback
                    style={{ display: 'inline-block', margin: '0 10px', width: 240 }}
                    rules={[
                      { required: true, message: '支払金額を入力してください' },
                      {
                        pattern: /^[1-9][0-9]*$/,
                        message: '支払金額の形式が正しくありません',
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder="例：6800" />
                  </Form.Item>

                  <Form.Item
                    name="consultingFeePaymentMethod"
                    label="支払方法"
                    hasFeedback
                    style={{ display: 'inline-block', margin: 0, width: 220 }}
                    rules={[{ required: true, message: '支払方法を選択してください' }]}
                  >
                    <Radio.Group>
                      {Object.keys(FeePaymentMethodTypeLabel).map(type => (
                        <Radio value={type} key={type}>
                          {FeePaymentMethodTypeLabel[type]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Form.Item>
            <Form.Item label="仲介手数料" style={{ marginBottom: 30 }}>
              <Form.Item
                name="brokerageFeeCheckbox"
                valuePropName="checked"
                style={{ display: 'inline-block', margin: '0 5px 0 0', width: 15 }}
                hasFeedback
              >
                <Checkbox style={{ padding: '5px 10px 5px 0' }} />
              </Form.Item>

              {isBrokerageFeeChecked && (
                <>
                  <Form.Item
                    name="brokerageFeeAmount"
                    label="支払金額"
                    hasFeedback
                    style={{ display: 'inline-block', margin: '0 10px', width: 240 }}
                    rules={[
                      { required: true, message: '支払金額を入力してください' },
                      {
                        pattern: /^[1-9][0-9]*$/,
                        message: '支払金額の形式が正しくありません',
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder="例：6800" />
                  </Form.Item>

                  <Form.Item
                    name="brokerageFeePaymentMethod"
                    label="支払方法"
                    hasFeedback
                    style={{ display: 'inline-block', margin: 0, width: 220 }}
                    rules={[{ required: true, message: '支払方法を選択してください' }]}
                  >
                    <Radio.Group>
                      {Object.keys(FeePaymentMethodTypeLabel).map(type => (
                        <Radio value={type} key={type}>
                          {FeePaymentMethodTypeLabel[type]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Form.Item>
            <Form.Item label="その他" style={{ marginBottom: 30 }}>
              <Form.Item
                name="otherFeeCheckbox"
                valuePropName="checked"
                style={{ display: 'inline-block', margin: '0 5px 0 0', width: 15 }}
                hasFeedback
              >
                <Checkbox style={{ padding: '5px 10px 5px 0' }} />
              </Form.Item>

              {isOtherFeeChecked && (
                <>
                  <Form.Item
                    name="otherFeeDetail"
                    label="詳細"
                    hasFeedback
                    style={{
                      display: 'inline-block',
                      width: 450,
                      marginLeft: 10,
                      marginBottom: 10,
                    }}
                    rules={[
                      { required: true, message: 'その他詳細を入力してください' },
                      {
                        max: 100,
                        message: 'その他詳細は100文字以内で入力してください',
                      },
                    ]}
                  >
                    <Input maxLength={100} />
                  </Form.Item>

                  <Form.Item
                    name="otherFeeAmount"
                    label="支払金額"
                    hasFeedback
                    style={{ display: 'inline-block', margin: '0 10px 0 30px', width: 240 }}
                    rules={[
                      { required: true, message: '支払金額を入力してください' },
                      {
                        pattern: /^[1-9][0-9]*$/,
                        message: '支払金額の形式が正しくありません',
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder="例：6800" />
                  </Form.Item>

                  <Form.Item
                    name="otherFeePaymentMethod"
                    label="支払方法"
                    hasFeedback
                    style={{ display: 'inline-block', margin: 0, width: 220 }}
                    rules={[{ required: true, message: '支払方法を選択してください' }]}
                  >
                    <Radio.Group>
                      {Object.keys(FeePaymentMethodTypeLabel).map(type => (
                        <Radio value={type} key={type}>
                          {FeePaymentMethodTypeLabel[type]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Form.Item>
          </>
        )}

        <Form.Item
          name="brokerageFeeBuyer"
          label="仲介手数料（買主）"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              required: true,
              message: '仲介手数料（買主）を選択してください',
            },
          ]}
        >
          <Radio.Group size="large">
            {Object.keys(YesNoFlagLabel).map(type => (
              <Radio value={type} key={type}>
                {YesNoFlagLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentBrokerageFeeBuyer === YesNoFlag.YES.toString() && (
          <>
            <Form.Item
              name="brokerageFeeBuyerCalculateMethod"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（買主）
                  <br />
                  計算式
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: true,
                  message: '仲介手数料（買主）計算計算式を選択してください',
                },
              ]}
            >
              <Select size="large" placeholder="仲介手数料（買主）計算式を選択してください">
                {Object.keys(CalculateFormulaLabel).map(code => (
                  <Option key={code} value={code}>
                    {CalculateFormulaLabel[code]}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {currentBrokerageFeeBuyerCalculateMethod ===
              CalculateFormula.FORMULA_OTHER.toString() && (
              <Form.Item
                name="brokerageFeeBuyerCalculateMethodDetail"
                label={
                  <span style={{ fontSize: 12 }}>
                    仲介手数料（買主）
                    <br />
                    その他計算式
                  </span>
                }
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '仲介手数料（買主）その他計算式を入力してください',
                  },
                  {
                    max: 100,
                    message: '仲介手数料（買主）その他計算式を入力してください',
                  },
                ]}
              >
                <YakInput
                  setval={value => {
                    form.setFieldValue('brokerageFeeBuyerCalculateMethodDetail', value);
                  }}
                  size="large"
                  maxLength={100}
                />
              </Form.Item>
            )}

            <Form.Item
              name="brokerageFeeBuyerAmount"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（買主）
                  <br />
                  金額（円）
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '仲介手数料（買主）金額を入力してください' },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '仲介手数料（買主）金額の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：680000" />
            </Form.Item>

            <Form.Item
              name="brokerageFeeBuyerPayeeName"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（買主）
                  <br />
                  支払先
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '支払先を入力してください' },
              ]}
            >
              <Input size="large" maxLength={45} placeholder="例：田中 太郎" />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="commissionFeeBuyer"
          label="業務委託料（買主）"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              required: true,
              message: '業務委託料（買主）を選択してください',
            },
          ]}
        >
          <Radio.Group size="large">
            {Object.keys(YesNoFlagLabel).map(type => (
              <Radio value={type} key={type}>
                {YesNoFlagLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentCommissionFeeBuyer === YesNoFlag.YES.toString() && (
          <>
            <Form.Item
              name="commissionFeeBuyerCalculateMethod"
              label={
                <span style={{ fontSize: 12 }}>
                  業務委託料（買主）
                  <br />
                  計算式
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: true,
                  message: '業務委託料（買主）計算計算式を選択してください',
                },
              ]}
            >
              <Select size="large" placeholder="業務委託料（買主）計算式を選択してください">
                {Object.keys(CalculateFormulaLabel).map(code => (
                  <Option key={code} value={code}>
                    {CalculateFormulaLabel[code]}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {currentCommissionFeeBuyerCalculateMethod ===
              CalculateFormula.FORMULA_OTHER.toString() && (
              <Form.Item
                name="commissionFeeBuyerCalculateMethodDetail"
                label={
                  <span style={{ fontSize: 12 }}>
                    業務委託料（買主）
                    <br />
                    その他計算式
                  </span>
                }
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '業務委託料（買主）その他計算式を入力してください',
                  },
                  {
                    max: 100,
                    message: '業務委託料（買主）その他計算式を入力してください',
                  },
                ]}
              >
                <YakInput
                  setval={value => {
                    form.setFieldValue('commissionFeeBuyerCalculateMethodDetail', value);
                  }}
                  size="large"
                  maxLength={100}
                />
              </Form.Item>
            )}

            <Form.Item
              name="commissionFeeBuyerAmount"
              label={
                <span style={{ fontSize: 12 }}>
                  業務委託料（買主）
                  <br />
                  金額（円）
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '業務委託料（買主）金額を入力してください' },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '業務委託料（買主）金額の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：680000" />
            </Form.Item>

            <Form.Item
              name="commissionFeeBuyerPayeeName"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（買主）
                  <br />
                  支払先
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '支払先を入力してください' },
              ]}
            >
              <Input size="large" maxLength={45} placeholder="例：田中 太郎" />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="brokerageFeeSeller"
          label="仲介手数料（売主）"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              required: true,
              message: '仲介手数料（売主）を選択してください',
            },
          ]}
        >
          <Radio.Group size="large">
            {Object.keys(YesNoFlagLabel).map(type => (
              <Radio value={type} key={type}>
                {YesNoFlagLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentBrokerageFeeSeller === YesNoFlag.YES.toString() && (
          <>
            <Form.Item
              name="brokerageFeeSellerCalculateMethod"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（売主）
                  <br />
                  計算式
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: true,
                  message: '仲介手数料（売主）計算計算式を選択してください',
                },
              ]}
            >
              <Select size="large" placeholder="仲介手数料（売主）計算式を選択してください">
                {Object.keys(CalculateFormulaLabel).map(code => (
                  <Option key={code} value={code}>
                    {CalculateFormulaLabel[code]}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {currentBrokerageFeeSellerCalculateMethod ===
              CalculateFormula.FORMULA_OTHER.toString() && (
              <Form.Item
                name="brokerageFeeSellerCalculateMethodDetail"
                label={
                  <span style={{ fontSize: 12 }}>
                    仲介手数料（売主）
                    <br />
                    その他計算式
                  </span>
                }
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '仲介手数料（売主）その他計算式を入力してください',
                  },
                  {
                    max: 100,
                    message: '仲介手数料（売主）その他計算式を入力してください',
                  },
                ]}
              >
                <YakInput
                  setval={value => {
                    form.setFieldValue('brokerageFeeSellerCalculateMethodDetail', value);
                  }}
                  size="large"
                  maxLength={100}
                />
              </Form.Item>
            )}

            <Form.Item
              name="brokerageFeeSellerAmount"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（売主）
                  <br />
                  金額（円）
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '仲介手数料（売主）金額を入力してください' },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '仲介手数料（売主）金額の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：680000" />
            </Form.Item>
            
            <Form.Item
              name="brokerageFeeSellerPayeeName"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（買主）
                  <br />
                  支払先
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '支払先を入力してください' },
              ]}
            >
              <Input size="large" maxLength={45} placeholder="例：田中 太郎" />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="commissionFeeSeller"
          label="業務委託料（売主）"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              required: true,
              message: '業務委託料（売主）を選択してください',
            },
          ]}
        >
          <Radio.Group size="large">
            {Object.keys(YesNoFlagLabel).map(type => (
              <Radio value={type} key={type}>
                {YesNoFlagLabel[type]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {currentCommissionFeeSeller === YesNoFlag.YES.toString() && (
          <>
            <Form.Item
              name="commissionFeeSellerCalculateMethod"
              label={
                <span style={{ fontSize: 12 }}>
                  業務委託料（売主）
                  <br />
                  計算式
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  required: true,
                  message: '業務委託料（売主）計算計算式を選択してください',
                },
              ]}
            >
              <Select size="large" placeholder="業務委託料（売主）計算式を選択してください">
                {Object.keys(CalculateFormulaLabel).map(code => (
                  <Option key={code} value={code}>
                    {CalculateFormulaLabel[code]}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {currentCommissionFeeSellerCalculateMethod ===
              CalculateFormula.FORMULA_OTHER.toString() && (
              <Form.Item
                name="commissionFeeSellerCalculateMethodDetail"
                label={
                  <span style={{ fontSize: 12 }}>
                    業務委託料（売主）
                    <br />
                    その他計算式
                  </span>
                }
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  {
                    required: true,
                    message: '業務委託料（売主）その他計算式を入力してください',
                  },
                  {
                    max: 100,
                    message: '業務委託料（売主）その他計算式を入力してください',
                  },
                ]}
              >
                <YakInput
                  setval={value => {
                    form.setFieldValue('commissionFeeSellerCalculateMethodDetail', value);
                  }}
                  size="large"
                  maxLength={100}
                />
              </Form.Item>
            )}

            <Form.Item
              name="commissionFeeSellerAmount"
              label={
                <span style={{ fontSize: 12 }}>
                  業務委託料（売主）
                  <br />
                  金額（円）
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '業務委託料（売主）金額を入力してください' },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '業務委託料（売主）金額の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：680000" />
            </Form.Item>

            <Form.Item
              name="commissionFeeSellerPayeeName"
              label={
                <span style={{ fontSize: 12 }}>
                  仲介手数料（買主）
                  <br />
                  支払先
                </span>
              }
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '支払先を入力してください' },
              ]}
            >
              <Input size="large" maxLength={45} placeholder="例：田中 太郎" />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="contactInformation"
          label="連絡事項"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            {
              max: 1000,
              message: '連絡事項を1000文字まで入力してください',
            },
          ]}
        >
          <TextArea rows={6} placeholder="連絡事項を入力してください" maxLength={1000} />
        </Form.Item>
      </>
    ),
    [
      ContractBuyerFields,
      ContractSellerFields,
      PropertyPriceField,
      currentAgencyAgreement,
      currentBrokerageFeeBuyer,
      currentBrokerageFeeBuyerCalculateMethod,
      currentBrokerageFeeSeller,
      currentBrokerageFeeSellerCalculateMethod,
      currentCommissionFeeBuyer,
      currentCommissionFeeBuyerCalculateMethod,
      currentCommissionFeeSeller,
      currentCommissionFeeSellerCalculateMethod,
      currentContractLocation,
      currentContractMethod,
      currentDraftingOfImportantMatterExplanation,
      currentOmittedInBetweenType,
      currentOverseasMoneyTransferType,
      currentOverseasRemittanceType,
      currentPaymentOfIntroductionFeeCommissionConsultingFee,
      currentPriceTaxType,
      currentTransactionMode,
      form,
      isBrokerageFeeChecked,
      isCommissionFeeChecked,
      isConsultingFeeChecked,
      isIntroductionFeeChecked,
      isOtherFeeChecked,
    ],
  );

  const insertSaleProperty = data => {
    authHttpInteractor
      .put(`/api/saleProperty`, data)
      .then(response => {
        setSubmitStatus(SubmitStatus.SUCCESS);
        setPropertyRecord(response.data.propertyRecord);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          updateErrorMessage(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          updateErrorMessage(error.response.message);
        } else {
          updateErrorMessage(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
        setSubmitStatus(SubmitStatus.FAILURE);
      });
  };

  const onValidateOk = values => {
    console.log(values);
    const payload = getJwtPayload();

    const requestData = {
      transactionType: TransactionType.SALE,
      // 【契約状態】契約開始 もしくは客付け(客付けの場合は、契約情報が必須)の場合は「事務受付待ち」、以外は「新規」
      contractStatus: isContractStarted
        ? ContractStatus.WAITING_FOR_ADMINISTRATIVE_PROCESSING
        : ContractStatus.NEW,
      // XXX BEでやるべき？
      submitter: payload.employeeId,

      /**
       * 基本情報
       */
      ...{
        // 【取引態様】
        transactionMode: values.transactionMode,
        // 【メイン担当者】
        mainContactPerson: values.mainContactPerson,
        // 【担当者】
        contactPersonList: values.contactPersonList
          ? values.contactPersonList.map(val => ({ employeeId: val }))
          : [],
        // 【物件種別】
        propertyType: values.propertyType,
        // 【物件状況】
        vacancyStatus: values.vacancyStatus,
        // 【物件名】
        name: CommonUtility.replaceFullNumber2Half(values.name),
        // 【部屋番号】物件種別 は 売りマンション の場合のみ
        ...([SalePropertyType.CONDOMINIUM.toString()].includes(values.propertyType)
          ? { roomNumber: CommonUtility.replaceFullNumber2Half(values.roomNumber) }
          : {}),
        // 【住居表示（住所）】
        address: CommonUtility.replaceFullNumber2Half(values.address),
        // 【売買代金】
        price: values.price,

        // 【自社広告掲載】：「客付け」の場合は 自社広告掲載 存在しない
        ...(values.inHouseAdvertising ? { inHouseAdvertising: values.inHouseAdvertising } : {}),
        // 【自社広告掲載】有り場合の付属項目
        ...(values.inHouseAdvertising === YesNoFlag.YES.toString()
          ? {
              // 【レインズ等の登録】
              reinsAdvertising: values.reinsAdvertising,
              // 【広告プラットフォーム】
              inHouseAdvertisingPlatformList: values.inHouseAdvertisingPlatformList.map(val => ({
                platformId: val,
              })),
              // 【取引状態】
              transactionStatus: values.transactionStatus,
              // 【内見方法】及び付属項目
              viewingMethod: {
                // 【内見方法】
                viewingMethod: values.viewingMethod,

                // 店頭鍵貸出 の場合
                ...(values.viewingMethod === SaleViewingMethod.KEY_LENDING_AT_THE_STORE.toString()
                  ? {
                      // 【預り業者名】
                      entrustedPartyContactMethod: CommonUtility.replaceFullNumber2Half(
                        values.entrustedPartyContactMethod,
                      ),
                      // 【預り業者場所】
                      entrustedPartyLocation: CommonUtility.replaceFullNumber2Half(
                        values.entrustedPartyLocation,
                      ),
                      // 【預り業者連絡方法】
                      entrustedPartyName: CommonUtility.replaceFullNumber2Half(
                        values.entrustedPartyName,
                      ),
                    }
                  : {}),
                // 内見不可 の場合
                ...(values.viewingMethod === SaleViewingMethod.NO_VIEWING.toString()
                  ? {
                      // 【不可の原因】
                      viewingNotPossibleReason: CommonUtility.replaceFullNumber2Half(
                        values.viewingNotPossibleReason,
                      ),
                      // 【内見可能となる時間】
                      viewingPossibleTime: CommonUtility.replaceFullNumber2Half(
                        values.viewingPossibleTime,
                      ),
                    }
                  : {}),
                // 現地対応
                ...(values.viewingMethod === SaleViewingMethod.ON_SITE_SUPPORT.toString()
                  ? {
                      // 【キーボックスの保存場所】
                      keyBoxLocation: CommonUtility.replaceFullNumber2Half(values.keyBoxLocation),
                      // 【キーボックスのパスワード】
                      keyBoxPassword: CommonUtility.replaceFullNumber2Half(values.keyBoxPassword),
                    }
                  : {}),
              },
              // 【他社広告掲載】
              thirdPartyAdvertising: values.thirdPartyAdvertising,
            }
          : {}),
      },
      // 【備考】
      ...(values.remarks ? { remarks: values.remarks } : {}),

      // -------------------------------------
      // 契約関連情報
      // -------------------------------------

      ...(isContractStarted
        ? {
            contractInfoRequestDto: {
              /**
               * 【契約情報】買主
               */
              ...{
                // 【買主名義種類】
                buyerTitleType: values.buyerTitleType,
                // 【買主名義】
                buyerTitle: [
                  {
                    customerTitleType: values.buyerCustomerTitleType,
                    name: values.buyerCustomerTitle,
                  },
                  ...(values.buyerTitleType === TitleType.JOINT.toString() &&
                  values.buyerCustomerTitleList
                    ? values.buyerCustomerTitleList
                    : []
                  ).map(v => ({
                    customerTitleType: v.buyerCustomerTitleType,
                    name: v.buyerCustomerTitle,
                  })),
                ],
                // 【買主住所タイプ】
                buyerAddressType: values.buyerAddressType,
                // 【買主住所】
                ...(values.buyerAddress ? { buyerAddress: values.buyerAddress } : {}),
                // 【買主TEL】
                ...(values.buyerPhoneNumber ? { buyerPhoneNumber: values.buyerPhoneNumber } : {}),
                // 【購入目的】
                buyerPurchasePurpose: values.buyerPurchasePurpose,
                // 【資金調達手段】
                buyerFundingMethod: values.buyerFundingMethod,
                // 【資金調達手段】がローンの場合の付属項目
                ...([
                  FundingMethodType.HOUSING_LOAN.toString(),
                  FundingMethodType.INVESTMENT_LOAN.toString(),
                ].includes(values.buyerFundingMethod.toString())
                  ? {
                      // 【銀行名称】
                      buyerFinancingBank: values.buyerFinancingBank,
                      // 【銀行名称】が その他 の場合の付属項目
                      ...(values.buyerFinancingBank === '26'
                        ? {
                            buyerFinancingBankFree: values.buyerFinancingBankFree,
                          }
                        : {}),
                      // 【借入支店名】
                      ...(values.buyerBorrowingBranchName
                        ? { buyerBorrowingBranchName: values.buyerBorrowingBranchName }
                        : {}),
                      // 【融資銀行の担当者名】
                      ...(values.buyerFinancingBankContactName
                        ? { buyerFinancingBankContactName: values.buyerFinancingBankContactName }
                        : {}),
                      // 【融資金額】
                      buyerFinancingAmount: values.buyerFinancingAmount,
                      // 【借入金利】
                      buyerBorrowingInterestRate: values.buyerBorrowingInterestRate,
                      // 【借入年限】
                      buyerBorrowingTerm: values.buyerBorrowingTerm,
                      // 【融資特約解除予定日】
                      buyerFinancingSpecialAgreementTerminationDate: dayjs(
                        values.buyerFinancingSpecialAgreementTerminationDate,
                      ).format('YYYY-MM-DD'),
                    }
                  : {}),
              },

              /**
               * 【契約情報】売主
               */
              ...{
                // 【売主名義種類】
                sellerTitleType: values.sellerTitleType,
                // 【売主名義】
                sellerTitle: [
                  {
                    customerTitleType: values.sellerCustomerTitleType,
                    name: values.sellerCustomerTitle,
                  },
                  ...(values.sellerTitleType === TitleType.JOINT.toString() &&
                  values.sellerCustomerTitleList
                    ? values.sellerCustomerTitleList
                    : []
                  ).map(v => ({
                    customerTitleType: v.sellerCustomerTitleType,
                    name: v.sellerCustomerTitle,
                  })),
                ],
                // 【売主住所タイプ】
                sellerAddressType: values.sellerAddressType,
                // 【売主住所】
                ...(values.sellerAddress ? { sellerAddress: values.sellerAddress } : {}),
                // 【売主TEL】
                ...(values.sellerPhoneNumber
                  ? { sellerPhoneNumber: values.sellerPhoneNumber }
                  : {}),
              },

              /**
               * 【契約情報】物件
               */
              ...{
                // 【自社での謄本取得履歴】
                recordOfCertifiedCopy: values.recordOfCertifiedCopy,
                // 【告知物件】
                disclosureProperty: values.disclosureProperty,
                // 【告知物件】が該当する場合の【告知物件詳細】
                ...(values.disclosureProperty === YesNoFlag.YES
                  ? { disclosurePropertyMemo: values.disclosurePropertyMemo }
                  : {}),
                // 【残置物処理】
                handlingRemainingItems: values.handlingRemainingItems,
                // 【残置物処理】有の場合の付属項目
                ...(values.handlingRemainingItems === YesNoFlag.YES.toString()
                  ? {
                      // 【残置物撤去処分】
                      remainingItemsRemovalDisposer: values.remainingItemsRemovalDisposer,
                      // 【残置物撤去費用負担】
                      remainingItemsRemovalCostResponsibility:
                        values.remainingItemsRemovalCostResponsibility,
                    }
                  : {}),
                // 【契約不適合】
                contractNonconformity: values.contractNonconformity,
                // 【設備瑕疵担保】
                facilityDefectGuarantee: values.facilityDefectGuarantee,
                // 【新重要事項調査報告書】
                newImportantMatterInvestigationReport: values.newImportantMatterInvestigationReport,
                // 【新重要事項調査報告書】が 手配不要 の場合の付属項目
                ...(values.newImportantMatterInvestigationReport === YesNoFlag.NO
                  ? {
                      // 【現調査報告書発行日】
                      nowImportantMatterInvestigationReportIssueDate: dayjs(
                        values.nowImportantMatterInvestigationReportIssueDate,
                      ).format('YYYY-MM-DD'),
                    }
                  : {}),
                // 【レインズに登録日】
                ...(values.reinsRegistrationDate
                  ? { reinsRegistrationDate: dayjs(values.reinsRegistrationDate) }
                  : {}),
              },

              /**
               * 【契約情報】契約
               */
              ...{
                // 【建物消費税】
                priceTaxType: values.priceTaxType,
                // 【建物消費税】が かかる の場合、その付属項目
                ...(values.priceTaxType === YesNoFlag.YES
                  ? {
                      taxAmount: values.taxAmount,
                    }
                  : {}),
                // 【手付金額】
                earnestMoneyAmount: values.earnestMoneyAmount,
                // 【契約日】
                contractDate: dayjs(values.contractDate).format('YYYY-MM-DD HH:mm:ss'),
                // 【決済予定日】
                scheduledSettlementDate: dayjs(values.scheduledSettlementDate).format('YYYY-MM-DD'),
                // 【中間省略】
                omittedInBetweenType: values.omittedInBetweenType,
                // 【中間省略】が その他 の場合の付属項目
                ...(values.omittedInBetweenType === OmittedInBetweenType.FREE.toString()
                  ? {
                      // 【中間省略名】
                      omittedInBetweenName: values.omittedInBetweenName,
                    }
                  : {}),
                // 【契約方法】
                contractMethod: values.contractMethod,
                // 【契約方法】が 持ち回り契約 の場合の付属項目
                ...(values.contractMethod === ContactMethodType.ALTERNATING_CONTRACT.toString()
                  ? {
                      // 【契約方法最終版確定日】
                      contractMethodFixedTime: dayjs(values.contractMethodFixedTime).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    }
                  : {}),
                // 【契約場所】
                contractLocation: values.contractLocation,
                // 【契約場所】が その他 の場合の付属項目
                ...(values.contractLocation === ContactLocationType.OTHER.toString()
                  ? {
                      // 【契約場所備考】
                      contractLocationMemo: values.contractLocationMemo,
                    }
                  : {}),
                // 【重要事項説明者】
                importantMatterExplainer: values.importantMatterExplainer,
                // 【自社宅建士】
                inHouseRealEstateExpert: values.inHouseRealEstateExpert,
                // 【重説作成者】
                draftingOfImportantMatterExplanation: values.draftingOfImportantMatterExplanation,
                // 【重説作成者】が 相手業者 の場合の付属項目
                ...(values.draftingOfImportantMatterExplanation ===
                  DraftingOfImportantMatterExplanation.COUNTERPARTY_COMPANY.toString() &&
                values.draftingOfImportantMatterReceivedDate
                  ? {
                      draftingOfImportantMatterReceivedDate: dayjs(
                        values.draftingOfImportantMatterReceivedDate,
                      ).format('YYYY-MM-DD HH:mm:ss'),
                    }
                  : {}),
                // 【代理契約】
                agencyAgreement: values.agencyAgreement,
                // 【代理契約】が 代理契約あり（委任状未取得） の場合の付属項目
                ...(values.agencyAgreement === AgencyAgreementType.NOT_YET.toString()
                  ? {
                      // 【契約場所備考】
                      agencyAgreementDate: dayjs(values.agencyAgreementDate).format('YYYY-MM-DD'),
                    }
                  : {}),
                // 【海外入出金種類】
                overseasMoneyTransferType: values.overseasMoneyTransferType,
                // 【海外入出金種類】が 海外から送金有 or 入金送金両方有 の場合の付属項目
                ...([
                  OverseasMoneyTransferType.FROM_OVERSEAS_REMITTANCE.toString(),
                  OverseasMoneyTransferType.BOTH_INCOMING_AND_OUTGOING_REMITTANCE.toString(),
                ].includes(values.overseasMoneyTransferType.toString())
                  ? {
                      // 【海外入金種類】
                      overseasRemittanceType: values.overseasRemittanceType,
                      // 【海外入金種類】が 中国本土以外 or 中国以外 の場合の付属項目
                      ...([
                        OverseasRemittanceType.CHINA_NOT_MAIN_LAND.toString(),
                        OverseasRemittanceType.NOT_CHINA.toString(),
                      ].includes(values.overseasRemittanceType.toString())
                        ? {
                            // 【その省名・その国名】
                            overseasRemittanceDetail: values.overseasRemittanceDetail,
                          }
                        : {}),
                    }
                  : {}),
                // 【司法書士指定】
                ...(values.judicialScrivenerAppointing
                  ? { judicialScrivenerAppointing: values.judicialScrivenerAppointing }
                  : {}),
                // 【印紙負担】
                stampDutyResponsibility: values.stampDutyResponsibility,
                // 【印紙準備】
                stampDutyPreparation: values.stampDutyPreparation,
                // 【紹介料、業務委託料、コンサルティング料等の支給】
                paymentOfIntroductionFeeCommissionConsultingFee:
                  values.paymentOfIntroductionFeeCommissionConsultingFee,
                // 【紹介料】
                ...(values.introductionFeeCheckbox
                  ? {
                      introductionFeeAmount: values.introductionFeeAmount,
                      introductionFeePaymentMethod: values.introductionFeePaymentMethod,
                    }
                  : { introductionFeePaymentMethod: FeePaymentMethodType.NO }),
                // 【業務委託料】
                ...(values.commissionFeeCheckbox
                  ? {
                      commissionFeeAmount: values.commissionFeeAmount,
                      commissionFeePaymentMethod: values.commissionFeePaymentMethod,
                    }
                  : {}),
                // 【コンサルティング料】
                ...(values.consultingFeeCheckbox
                  ? {
                      consultingFeeAmount: values.consultingFeeAmount,
                      consultingFeePaymentMethod: values.consultingFeePaymentMethod,
                    }
                  : { consultingFeePaymentMethod: FeePaymentMethodType.NO }),
                // 【仲介手数料】
                ...(values.brokerageFeeCheckbox
                  ? {
                      brokerageFeeAmount: values.brokerageFeeAmount,
                      brokerageFeePaymentMethod: values.brokerageFeePaymentMethod,
                    }
                  : { brokerageFeePaymentMethod: FeePaymentMethodType.NO }),
                // 【その他】
                ...(values.otherFeeCheckbox
                  ? {
                      otherFeeAmount: values.otherFeeAmount,
                      otherFeePaymentMethod: values.otherFeePaymentMethod,
                      otherFeeDetail: values.otherFeeDetail,
                    }
                  : { otherFeePaymentMethod: FeePaymentMethodType.NO }),

                // 【仲介手数料（買主）】
                brokerageFeeBuyer: values.brokerageFeeBuyer,
                // 【仲介手数料（買主）】が 有 の場合
                ...(values.brokerageFeeBuyer === YesNoFlag.YES.toString()
                  ? {
                      // 【計算式】
                      brokerageFeeBuyerCalculateMethod: values.brokerageFeeBuyerCalculateMethod,

                      // 【計算式】が その他 の場合の計算式
                      ...(values.brokerageFeeBuyerCalculateMethod ===
                      CalculateFormula.FORMULA_OTHER.toString()
                        ? {
                            brokerageFeeBuyerCalculateMethodDetail:
                              values.brokerageFeeBuyerCalculateMethodDetail,
                          }
                        : {}),
                      // 【金額】
                      brokerageFeeBuyerAmount: values.brokerageFeeBuyerAmount,
                      brokerageFeeBuyerPayeeName: values.brokerageFeeBuyerPayeeName,
                    }
                  : {}),
                // 【業務委託料（買主）】
                commissionFeeBuyer: values.commissionFeeBuyer,
                // 【業務委託料（買主）】が 有 の場合の付属項目
                ...(values.commissionFeeBuyer === YesNoFlag.YES.toString()
                  ? {
                      // 【計算式】
                      commissionFeeBuyerCalculateMethod: values.commissionFeeBuyerCalculateMethod,

                      // 【計算式】が その他 の場合の計算式
                      ...(values.commissionFeeBuyerCalculateMethod ===
                      CalculateFormula.FORMULA_OTHER.toString()
                        ? {
                            commissionFeeBuyerCalculateMethodDetail:
                              values.commissionFeeBuyerCalculateMethodDetail,
                          }
                        : {}),

                      // 【金額】
                      commissionFeeBuyerAmount: values.commissionFeeBuyerAmount,
                      commissionFeeBuyerPayeeName: values.commissionFeeBuyerPayeeName,
                    }
                  : {}),
                // 【仲介手数料（売主）】
                brokerageFeeSeller: values.brokerageFeeSeller,
                // 【仲介手数料（売主）】が 有 の場合の付属項目
                ...(values.brokerageFeeSeller === YesNoFlag.YES.toString()
                  ? {
                      // 【計算式】
                      brokerageFeeSellerCalculateMethod: values.brokerageFeeSellerCalculateMethod,

                      // 【計算式】が その他 の場合の計算式
                      ...(values.brokerageFeeSellerCalculateMethod ===
                      CalculateFormula.FORMULA_OTHER.toString()
                        ? {
                            brokerageFeeSellerCalculateMethodDetail:
                              values.brokerageFeeSellerCalculateMethodDetail,
                          }
                        : {}),

                      // 【金額】
                      brokerageFeeSellerAmount: values.brokerageFeeSellerAmount,
                      brokerageFeeSellerPayeeName: values.brokerageFeeSellerPayeeName,
                    }
                  : {}),
                // 【業務委託料（売主）】
                commissionFeeSeller: values.commissionFeeSeller,
                // 【業務委託料（売主）】が 有 の場合の付属項目
                ...(values.commissionFeeSeller === YesNoFlag.YES.toString()
                  ? {
                      // 【計算式】
                      commissionFeeSellerCalculateMethod: values.commissionFeeSellerCalculateMethod,

                      // 【計算式】が その他 の場合の計算式
                      ...(values.commissionFeeSellerCalculateMethod ===
                      CalculateFormula.FORMULA_OTHER.toString()
                        ? {
                            commissionFeeSellerCalculateMethodDetail:
                              values.commissionFeeSellerCalculateMethodDetail,
                          }
                        : {}),
                      // 【金額】
                      commissionFeeSellerAmount: values.commissionFeeSellerAmount,
                      commissionFeeSellerPayeeName: values.commissionFeeSellerPayeeName,
                    }
                  : {}),

                // 【連絡事項】
                ...(values.contactInformation
                  ? { contactInformation: values.contactInformation }
                  : {}),
              },
            },
          }
        : {}),
    };

    console.log(requestData);

    insertSaleProperty(requestData);
  };

  return (
    <div>
      {/* 登録成功 */}
      {submitStatus === SubmitStatus.SUCCESS && (
        <div style={{ width: '100%' }}>
          <Result
            status="success"
            title="売買物件の登録は完了しました！"
            extra={[
              <Button
                onClick={() => {
                  form.resetFields();
                  setSubmitStatus(SubmitStatus.INITIAL);
                  setPropertyRecord(null);
                }}
                key="registerMore"
              >
                続けて登録
              </Button>,
              ...(propertyRecord && propertyRecord.contractStatus === ContractStatus.NEW
                ? [
                    <Button href={`/property/sale/edit/${propertyRecord.id}/`} key="saleDetail">
                      契約に進む
                    </Button>,
                  ]
                : []),
              <Button href="/property/sale/" key="saleList">
                売買物件一覧へ
              </Button>,
              <Button href="/property/sale/contract/" key="saleContractList">
                売買契約案件一覧へ
              </Button>,
              <Button href="/my/property/sale/" key="mySaleList">
                売買担当案件一覧へ
              </Button>,
            ]}
          />
        </div>
      )}

      {submitStatus !== SubmitStatus.SUCCESS && (
        <>
          {/* ドラフトボタン群 */}
          {DraftButtons}

          <Form
            name="createSaleProperty"
            form={form}
            disabled={submitStatus === SubmitStatus.INPROGRESS}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onValidateOk}
            onFinishFailed={({ errorFields }) => {
              CommonUtility.scrollToById(`createSaleProperty_${errorFields[0].name}`);
            }}
          >
            <Form.Item
              name="transactionMode"
              label="取引態様"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '取引態様を選択してください' }]}
            >
              <Radio.Group size="large">
                {Object.keys(SaleTransactionModeLabel).map(mode => (
                  <Radio value={mode} key={mode}>
                    {SaleTransactionModeLabel[mode]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {/* 元付け、客付け、両手の場合のみフォームを表示 */}
            {currentTransactionMode &&
            ![
              SaleTransactionMode.SOURCING.toString(),
              SaleTransactionMode.PLACEMENT.toString(),
              SaleTransactionMode.DUAL_AGENCY.toString(),
            ].includes(currentTransactionMode) ? (
              <div style={{ width: '100%', marginBottom: 30 }}>
                <Alert
                  description="仕入れ・再販は現在対応しておりません。"
                  type="warning"
                  showIcon
                />
              </div>
            ) : (
              <>
                {/* 基本情報 */}
                {BaseInfoFields}

                {/* 備考 */}
                <Form.Item
                  name="remarks"
                  label="備考"
                  hasFeedback
                  style={{ marginBottom: 30 }}
                  rules={[
                    {
                      max: 1000,
                      message: '備考を1000文字まで入力してください',
                    },
                  ]}
                >
                  <TextArea rows={6} placeholder="備考を入力してください" maxLength={1000} />
                </Form.Item>

                {/* 契約に進むボタン */}
                {/* 元付けと両手の場合、契約開始ボタンを押さないと契約開始ならない */}
                {[
                  SaleTransactionMode.SOURCING.toString(),
                  SaleTransactionMode.DUAL_AGENCY.toString(),
                ].includes(currentTransactionMode) && (
                  <Space
                    direction="vertical"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      onClick={() => {
                        setIsStartContract(!isStartContract);
                      }}
                      style={{
                        width: 500,
                        ...(isStartContract ? {} : { borderColor: '#52c41a', color: '#52c41a' }),
                      }}
                      danger
                    >
                      {isStartContract ? '↑↑↑ 契約情報入力をやめる ↑↑↑' : '↓↓↓ 契約に進む ↓↓↓'}
                    </Button>
                  </Space>
                )}

                {/* 客付け、または契約開始ボタン押された場合、契約Fieldsを表示 */}
                {isContractStarted && (
                  <>
                    {/* 【買主Fields】 両手・客つけの場合 */}
                    {[
                      SaleTransactionMode.DUAL_AGENCY.toString(),
                      SaleTransactionMode.PLACEMENT.toString(),
                    ].includes(currentTransactionMode) && (
                      <>
                        <Divider orientation="left">
                          <span style={{ fontWeight: 'bold' }}>
                            ◆自社担当する顧客情報<span style={{ color: 'red' }}>（買主）</span>
                          </span>
                        </Divider>
                        {ContractBuyerFields}
                      </>
                    )}

                    {/* 【売主Fields】 両手・元つけの場合 */}
                    {[
                      SaleTransactionMode.DUAL_AGENCY.toString(),
                      SaleTransactionMode.SOURCING.toString(),
                    ].includes(currentTransactionMode) && (
                      <>
                        <Divider orientation="left">
                          <span style={{ fontWeight: 'bold' }}>
                            ◆自社担当する顧客情報<span style={{ color: 'red' }}>（売主）</span>
                          </span>
                        </Divider>
                        {ContractSellerFields}
                      </>
                    )}

                    <Divider orientation="left">
                      <span style={{ fontWeight: 'bold' }}>◆物件情報</span>
                    </Divider>
                    {ContractPropertyFields}

                    <Divider orientation="left">
                      <span style={{ fontWeight: 'bold' }}>◆契約情報</span>
                    </Divider>
                    {ContractInfoFields}
                  </>
                )}

                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ width: 300 }}
                    icon={<FolderAddOutlined />}
                  >
                    登録する
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </>
      )}
    </div>
  );
}
