import React, { useEffect, useState } from 'react';

import {
  LockOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  UnlockOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, Select } from 'antd';

import { SubmitStatus } from '../../constants';
import { useAuth } from '../../hooks/useAuth';
import httpInteractor from '../../interactors/httpInteractor';
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout';
import { AuthUtility } from '../../utilities/AuthUtility';

const { Option } = Select;

function LoginPage() {
  // TODO 共通化
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const [errorMsg, setErrorMsg] = useState();
  const { setJwtToken } = useAuth();

  const onValidateOk = values => {
    const requestData = {
      email: values.email,
      password: AuthUtility.hashPassword(values.password),
      subCompanyId: values.subCompany,
    };

    setSubmitStatus(SubmitStatus.INPROGRESS);

    httpInteractor
      .post('/api/authToken', requestData)
      .then(response => {
        setSubmitStatus(SubmitStatus.SUCCESS);
        setJwtToken(response.data.accessToken, response.data.refreshToken);
        window.location.href = '/';
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          setErrorMsg(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          setErrorMsg(error.response.message);
        } else {
          // TODO 確認；404(ユーザー存在しない)もこちらで良い？
          setErrorMsg(
            <>
              大変申し訳ありませんが、エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
        setSubmitStatus(SubmitStatus.FAILURE);
      });
  };

  // 子会社一覧を取得
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    httpInteractor
      .get('/api/organization/subCompany')
      .then(response => {
        setCompanyList(response.data);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        setSubmitStatus(SubmitStatus.FAILURE);

        // 認証不要のため、特にユーザーに見せるメッセージがありませんので
        // 汎用的なメッセージを出します。
        setErrorMsg(
          <>
            大変申し訳ありませんが、エラーを発生しました。
            <br />
            通信状況をご確認の上、再度お試しください。
          </>,
        );
      });
  }, []);

  const content = () => (
    <div>
      {/* FIXME GlobalLayout に移動 */}
      {/* エラーメッセージ */}
      {submitStatus === SubmitStatus.FAILURE && (
        <div style={{ width: 600, marginBottom: 30 }}>
          <Alert message="エラー" description={errorMsg} type="error" showIcon />
        </div>
      )}

      {/* フォーム */}
      {submitStatus !== SubmitStatus.SUCCESS && (
        <Card title={<div style={{ textAlign: 'center' }}>ログイン</div>} style={{ width: 600 }}>
          <Form name="login" layout="vertical" requiredMark={false} onFinish={onValidateOk}>
            <Form.Item
              name="subCompany"
              disabled={submitStatus === SubmitStatus.INPROGRESS}
              hasFeedback
              rules={[{ required: true, message: '会社を選択してください' }]}
            >
              <Select size="large" placeholder="会社を選択してください">
                {companyList.map(company => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="email"
              hasFeedback
              style={{ marginBottom: 25 }}
              rules={[
                { required: true, type: 'email', message: 'メールアドレスを入力してください' },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@(yak-jp\.com|yakas-jp\.com|yakdp-jp\.com|yakwl\.co\.jp|oak-jp\.com|ten-ji\.co\.jp)$/,
                  message: 'メールアドレスの形式が正しくありません',
                },
                {
                  max: 319,
                  message: 'メールアドレスは319文字以内で入力してください',
                },
              ]}
            >
              <Input
                size="large"
                maxLength={319}
                placeholder="メールアドレスを入力してください"
                prefix={<MailOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              hasFeedback
              rules={[
                { required: true, message: 'パスワードを入力してください' },
                {
                  max: 32,
                  message: 'パスワードは32文字以内で入力してください',
                },
              ]}
            >
              <Input.Password
                size="large"
                maxLength={32}
                placeholder="パスワード"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={submitStatus === SubmitStatus.INPROGRESS}
                size="large"
                style={{ width: 300 }}
                icon={<UnlockOutlined />}
              >
                ログインする
              </Button>
            </Form.Item>
          </Form>

          <div style={{ textAlign: 'center' }}>
            <Button type="link" href="/password/reset/" icon={<QuestionCircleOutlined />}>
              パスワードをお忘れの方はこちら
            </Button>
          </div>

          <div style={{ textAlign: 'center' }}>
            <Button type="link" href="/signup/" icon={<UserAddOutlined />}>
              新規登録はこちら
            </Button>
          </div>
        </Card>
      )}
    </div>
  );

  return GlobalLayout(content());
}

export default LoginPage;
