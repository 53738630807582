import React, { useContext, useEffect, useState } from 'react';

import { Card, Tabs } from 'antd';

import { Role } from '../../constants';
import { ErrorContext } from '../../contexts/ErrorContext';
import { useAuth } from '../../hooks/useAuth';
import authHttpInteractor from '../../interactors/authHttpInteractor';
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout';

import { InputChintaiProperty } from './parts/InputChintaiProperty';
import { InputSaleProperty } from './parts/InputSaleProperty';

export function RegisterPropertyPage() {
  const { updateErrorMessage } = useContext(ErrorContext);
  const { getJwtPayload } = useAuth();
  const [salesEmployeeList, setSalesEmployeeList] = useState();

  // 営業社員一覧を取得
  const fetchSalesEmployeeList = () => {
    const payload = getJwtPayload();
    // XXX 営業社員一覧API待ち
    authHttpInteractor
      .get(
        `/api/organization/${payload.subCompany}/employeeWithRole/?role=${Role.SALES},${Role.SALES_MANAGER}`,
      )
      .then(response => {
        setSalesEmployeeList(
          response.data.map(val => ({
            value: val.id,
            label: `${val.familyName} ${val.givenName}`,
          })),
        );
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          updateErrorMessage(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          updateErrorMessage(error.response.message);
        } else {
          updateErrorMessage(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
      });
  };

  useEffect(() => {
    fetchSalesEmployeeList();
  }, []);

  const content = () => (
    <div>
      <Card title={<div style={{ textAlign: 'center' }}>物件登録</div>} style={{ width: 800 }}>
        <Tabs
          defaultActiveKey="sale"
          items={[
            {
              key: 'sale',
              label: `売買物件`,
              children: <InputSaleProperty salesEmployeeList={salesEmployeeList} />,
            },
            {
              key: 'chintai',
              label: `賃貸物件`,
              children: <InputChintaiProperty salesEmployeeList={salesEmployeeList} />,
            },
          ]}
        />
      </Card>
    </div>
  );

  const breadcrumbList = [
    {
      title: <a href="/">トップ</a>,
    },
    {
      title: '物件登録',
    },
  ];

  return GlobalLayout(content(), breadcrumbList);
}
