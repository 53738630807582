import React, { useEffect, useRef, useState } from 'react';

import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Form, Input, Upload } from 'antd';
import ReactQuill from 'react-quill';

import authHttpInteractor from '../../../interactors/authHttpInteractor';
import uploadHttpInteractor from '../../../interactors/uploadHttpInteractor';

import 'react-quill/dist/quill.snow.css';

export function EditMessageDrawer({ isOpen, messageItem, onClose, onEditSuccess }) {
  const [errorMsg, setErrorMsg] = useState();
  const [form] = Form.useForm();

  const uploadFile = ({ file, onSuccess }) => {
    uploadHttpInteractor
      .post(`/api/upload`, { file })
      .then(response => {
        onSuccess({
          uid: response.data.id,
          name: response.data.fileName,
          status: 'done',
          url: response.data.downloadUrl,
        });
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          setErrorMsg(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          setErrorMsg(error.response.message);
        } else {
          setErrorMsg(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
      });
  };

  const onValidateOk = (internalMessageId, values) => {
    const requestData = {
      body: values.msgBody,
      title: values.msgTitle,
      attachedFiles: values.uploadFiles ? values.uploadFiles.map(f => f.response ? f.response.uid : f.uid) : [],
    };

    authHttpInteractor
      .patch(`/api/internalMessage/${internalMessageId}`, requestData)
      .then(() => {
        form.resetFields();
        onEditSuccess();
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          setErrorMsg(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          setErrorMsg(error.response.message);
        } else {
          setErrorMsg(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
      });
  };

  const quillRef = useRef(null);

  const insertToEditor = (url, range) => {
    quillRef.current.editor.insertEmbed(range.index, 'image', url);
    quillRef.current.editor.setSelection(range.index + 1);
  };

  // 画像を保存する
  const saveImage = file => {
    console.log(file);
    // 画像挿入のレンジを取得
    const range = quillRef.current.editor.getSelection();

    uploadHttpInteractor
      .post(`/api/upload`, { file })
      .then(response => {
        console.log(response.data);
        // 画像をエディターに挿入
        insertToEditor(response.data.downloadUrl, range);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          setErrorMsg(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          setErrorMsg(error.response.message);
        } else {
          setErrorMsg(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
      });
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        saveImage(file);
      } else {
        alert('画像のみアップロードできます。');
      }
    };
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldValue('msgTitle', messageItem.title);
      form.setFieldValue('msgBody', messageItem.body);
      form.setFieldValue(
        'uploadFiles',
        messageItem.attachedFiles.map(f => ({
          uid: f.id,
          name: f.name,
          status: 'done',
          url: f.downloadUrl,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Drawer title="メッセージ変更" width={800} onClose={onClose} open={isOpen}>
      {/* エラーメッセージ */}
      {errorMsg && (
        <div style={{ width: '100%', marginBottom: 30 }}>
          <Alert message="エラー" description={errorMsg} type="error" showIcon />
        </div>
      )}

      <Form
        name="sendMessage"
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 24,
        }}
        layout="vertical"
        onFinish={values => onValidateOk(messageItem.id, values)}
      >
        <Form.Item
          name="msgTitle"
          label="タイトル"
          hasFeedback
          style={{ marginBottom: 30 }}
          rules={[
            { required: true, message: 'タイトルを入力してください' },
            {
              max: 65,
              message: 'タイトルは65文字以内で入力してください',
            },
          ]}
        >
          <Input size="large" maxLength={65} placeholder="例：社員旅行のスケジュールについて" />
        </Form.Item>

        {isOpen && (
          <Form.Item
            name="msgBody"
            label="本文"
            hasFeedback
            style={{ marginBottom: 60 }}
            rules={
              [
                // {
                //   max: 2000,
                //   message: '本文は2000文字以内で入力してください',
                // },
              ]
            }
          >
            <ReactQuill
              ref={quillRef}
              theme="snow"
              style={{ height: '300px' }}
              modules={{
                toolbar: {
                  container: [
                    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    // [{ font: [] }],

                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],

                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],

                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image'],
                    ['clean'],
                  ],
                  handlers: {
                    image: selectLocalImage,
                  },
                },
              }}
              formats={[
                // 'header',
                // 'font',

                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'code-block',

                'color',
                'background',
                'align',

                'list',
                'ordered',
                'bullet',
                'indent',
                'link',
                'image',
              ]}
            />
          </Form.Item>
        )}

        <Form.Item
          name="uploadFiles"
          label="添付ファイル"
          valuePropName="fileList"
          getValueFromEvent={e => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
          rules={[
            () => ({
              validator(_, value) {
                if (value && value.length > 10) {
                  return Promise.reject(new Error('一回の送信は10ファイルまでです'));
                }
                return Promise.resolve();
              },
            }),
          ]}
          style={{ marginBottom: 30 }}
        >
          <Upload
            customRequest={options => {
              uploadFile(options);
            }}
          >
            <Button icon={<UploadOutlined />}>添付ファイルをアップロード</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: 300 }}
            icon={<CheckOutlined />}
          >
            保存する
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
