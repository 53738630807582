import React, { lazy } from 'react';

import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { useAuth } from './hooks/useAuth';
import LoginPage from './pages/account/Login';
import PasswordResetPage from './pages/account/PasswordReset';
// import PasswordSetttingPage from './pages/account/PasswordSettting';
// import SignupPage from './pages/account/Signup';
// import CreateEmployeePage from './pages/admin/employee/CreateEmployee';
import ReceivedMessageListPage from './pages/message/ReceivedMessageList';
import SentMessageListPage from './pages/message/SentMessageList';
// import OrganizationTreePage from './pages/organization/OrganizationTree';
// import ChintaiPropertyListPage from './pages/property/ChintaiPropertyList';
import { EditChintaiPropertyPage } from './pages/property/EditChintaiProperty';
import { EditSalePropertyPage } from './pages/property/EditSaleProperty';
// import MyChintaiPropertyListPage from './pages/property/MyChintaiPropertyList';
// import MySalePropertyListPage from './pages/property/MySalePropertyList';
import { RegisterPropertyPage } from './pages/property/RegisterProperty';
// import SaleContractPropertyListPage from './pages/property/SaleContractPropertyList';
// import SalePropertyListPage from './pages/property/SalePropertyList';
// import ReinsPage from './pages/reins/reins'
// import ResourceReservePage from './pages/resource/Resource';
// import TopPage from './pages/top/Top';

// const PasswordResetPage = lazy(() => import('./pages/account/PasswordReset'));
const PasswordSetttingPage = lazy(() => import('./pages/account/PasswordSettting'));
const SignupPage = lazy(() => import('./pages/account/Signup'));
const CreateEmployeePage = lazy(() => import('./pages/admin/employee/CreateEmployee'));
// const ReceivedMessageListPage = lazy(() => import('./pages/message/ReceivedMessageList'));
// const SentMessageListPage = lazy(() => import('./pages/message/SentMessageList'));
const OrganizationTreePage = lazy(() => import('./pages/organization/OrganizationTree'));
const ChintaiPropertyListPage = lazy(() => import('./pages/property/ChintaiPropertyList'));
// const { EditChintaiPropertyPage } = lazy(() => import('./pages/property/EditChintaiProperty'));
// const { EditSalePropertyPage } = lazy(() => import('./pages/property/EditSaleProperty'));
const MyChintaiPropertyListPage = lazy(() => import('./pages/property/MyChintaiPropertyList'));
const MySalePropertyListPage = lazy(() => import('./pages/property/MySalePropertyList'));
// const { RegisterPropertyPage } = lazy(() => import('./pages/property/RegisterProperty'));
const SaleContractPropertyListPage = lazy(() => import('./pages/property/SaleContractPropertyList'));
const ChintaiContractPropertyListPage = lazy(() => import('./pages/property/ChintaiContractPropertyList'));
const SalePropertyListPage = lazy(() => import('./pages/property/SalePropertyList'));
const ReinsPage = lazy(() => import('./pages/reins/reins'));
const ResourceReservePage = lazy(() => import('./pages/resource/Resource'));
const TopPage = lazy(() => import('./pages/top/Top'));


function App() {
  const {
    isLoggedIn,
    isAdmin,
    isOrgAdmin,
    isStaff,
    isStaffManager,
    canSendOrgMsg,
    isSales,
    isSalesManager,
  } = useAuth();

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={isLoggedIn() ? <TopPage /> : <Navigate replace to="/login" />}
            index
          />
          <Route
            path="/login"
            element={isLoggedIn() ? <Navigate replace to="/" /> : <LoginPage />}
          />
          <Route path="/signup" element={<SignupPage />} />
          {/* <Route path="/account/edit/:accountId/" element={<AccountEditPage />} /> */}
          <Route path="/password/setting/:token/" element={<PasswordSetttingPage />} />
          <Route path="/password/reset/" element={<PasswordResetPage />} />

          {(isAdmin() || isOrgAdmin()) && (
            <>
              <Route
                path="/admin/employee/create/"
                element={isLoggedIn() ? <CreateEmployeePage /> : <Navigate replace to="/login" />}
              />
              {/* <Route
                path="/admin/employee/edit/:employeeId/"
                element={isLoggedIn() ? <EditEmployeePage /> : <Navigate replace to="/login" />}
              /> */}
            </>
          )}

          <Route
            path="/organization/tree/"
            element={isLoggedIn() ? <OrganizationTreePage /> : <Navigate replace to="/login" />}
          />

          <Route
            path="/resource/reserve/"
            element={isLoggedIn() ? <ResourceReservePage /> : <Navigate replace to="/login" />}
          />

          <Route
            path="/reins"
            exact
            element={isLoggedIn() ? <ReinsPage /> : <Navigate replace to="/login" />}
          />

          <Route
            path="/message/inbox/"
            element={isLoggedIn() ? <ReceivedMessageListPage /> : <Navigate replace to="/login" />}
          />
          {canSendOrgMsg() && (
            <Route
              path="/message/sent/"
              element={isLoggedIn() ? <SentMessageListPage /> : <Navigate replace to="/login" />}
            />
          )}

          {(isAdmin() || isSales() || isSalesManager() || isStaff() || isStaffManager()) && (
            <>
              <Route
                path="/property/register/"
                element={isLoggedIn() ? <RegisterPropertyPage /> : <Navigate replace to="/login" />}
              />
              <Route
                path="/property/chintai/"
                element={
                  isLoggedIn() ? <ChintaiPropertyListPage /> : <Navigate replace to="/login" />
                }
              />
              <Route
                path="/property/sale/"
                element={isLoggedIn() ? <SalePropertyListPage /> : <Navigate replace to="/login" />}
              />
            </>
          )}

          <Route
            path="/property/sale/edit/:propertyId/"
            element={isLoggedIn() ? <EditSalePropertyPage /> : <Navigate replace to="/login" />}
          />
          <Route
            path="/property/chintai/edit/:propertyId/"
            element={isLoggedIn() ? <EditChintaiPropertyPage /> : <Navigate replace to="/login" />}
          />

          {(isAdmin() || isStaff() || isStaffManager()) && (
            <Route
              path="/property/sale/contract/"
              element={
                isLoggedIn() ? <SaleContractPropertyListPage /> : <Navigate replace to="/login" />
              }
            />
          )}

          <Route
            path='/property/chintai/contract/'
            element={
              isLoggedIn() ? <ChintaiContractPropertyListPage /> : <Navigate replace to="/login" />
            }
          />

          {(isAdmin() || isSales() || isSalesManager()) && (
            <>
              <Route
                path="/my/property/chintai/"
                element={
                  isLoggedIn() ? <MyChintaiPropertyListPage /> : <Navigate replace to="/login" />
                }
              />
              <Route
                path="/my/property/sale/"
                element={
                  isLoggedIn() ? <MySalePropertyListPage /> : <Navigate replace to="/login" />
                }
              />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
