import React from 'react';

import { ConfigProvider } from 'antd';
import jaJP from 'antd/locale/ja_JP';
import 'dayjs/locale/ja';
import ReactDOM from 'react-dom/client';

import App from './App';
import { ErrorContextProvider } from './contexts/ErrorContext';

import './styles/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={jaJP}>
      <ErrorContextProvider>
        <App />
      </ErrorContextProvider>
    </ConfigProvider>
  </React.StrictMode>,
);
