import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { Role } from '../constants';

export const accessTokenOptions = {
  path: '/',
  maxAge: 12 * 60 * 60,
  ...(process.env.NODE_ENV === 'production'
    ? {
        httpOnly: false,
        secure: false, // TODO: should use true, but need test in prod
        sameSite: 'lax',
      }
    : {}),
};

export const refreshTokenOptions = {
  path: '/',
  maxAge: 12 * 60 * 60,
  ...(process.env.NODE_ENV === 'production'
    ? {
        httpOnly: false,
        secure: false,
        sameSite: 'lax',
      }
    : {}),
};

export const useAuth = () => {
  const setJwtToken = (at, rt) => {
    // アクセストークン
    setCookie(null, 'at', at, accessTokenOptions);
    // リフレッシュトークン
    setCookie(null, 'rt', rt, refreshTokenOptions);
  };

  const removeJwtToken = () => {
    destroyCookie(null, 'at', accessTokenOptions);
    destroyCookie(null, 'rt', refreshTokenOptions);
  };

  const getAccessToken = () => {
    const cookies = parseCookies();
    if (cookies && cookies.at) {
      return decodeURIComponent(cookies.at);
    }

    return null;
  };

  const getRefreshToken = () => {
    const cookies = parseCookies();
    if (cookies && cookies.rt) {
      return decodeURIComponent(cookies.rt);
    }

    return null;
  };

  const getJwtPayload = () => {
    const cookies = parseCookies();
    if (cookies && cookies.at) {
      return jwtDecode(cookies.at);
    }

    return {};
  };

  const isAccessTokenExpired = () => {
    const payload = getJwtPayload();
    return !payload && dayjs().isAfter(payload.exp * 1000);
  };

  // const isLoggedIn = () => {
  //   const payload = getJwtPayload();
  //   return payload && dayjs().isBefore(payload.exp * 1000);
  // };
  const isLoggedIn = () => getAccessToken() && getRefreshToken();

  const isAdmin = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.ADMIN);
  };

  const isOrgAdmin = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.ORG_ADMIN);
  };

  const canSendOrgMsg = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.SEND_ORG_MSG);
  };

  const isSales = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.SALES);
  };
  const isSalesManager = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.SALES_MANAGER);
  };

  const isStaff = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.STAFF);
  };
  const isStaffManager = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.STAFF_MANAGER);
  };

  // 契約推進課
  const isStaffSalesSupportGrp = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.STAFF_SALES_SUPPORT_GROUP);
  };

  // 总务课
  const isStaffGeneralStaffGrp = () => {
    const payload = getJwtPayload();
    return payload && payload.roleList && payload.roleList.includes(Role.STAFF_GENERAL_STAFF_GROUP);
  };

  return {
    setJwtToken,
    removeJwtToken,
    getAccessToken,
    getRefreshToken,
    getJwtPayload,
    isAccessTokenExpired,
    isLoggedIn,
    isAdmin,
    isOrgAdmin,
    canSendOrgMsg,
    isSales,
    isSalesManager,
    isStaff,
    isStaffManager,
    isStaffSalesSupportGrp,
    isStaffGeneralStaffGrp,
  };
};
