import React, { useContext, useEffect, useState } from 'react';

import { Alert, Button, Drawer, Space, Table } from 'antd';

import { ContractStatus, Role, SubmitStatus } from '../../../constants';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { useAuth } from '../../../hooks/useAuth';
import authHttpInteractor from '../../../interactors/authHttpInteractor';

export function AssignTransactionHandlerDrawer({ propertyId, isOpen, onCloseFn, onAssignDone }) {
  const { updateErrorMessage } = useContext(ErrorContext);
  const { getJwtPayload } = useAuth();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const [errorMsg, setErrorMsg] = useState();
  const [staffList, setStaffList] = useState([]);

  const fetchStaffList = () => {
    const payload = getJwtPayload();
    authHttpInteractor
      .get(
        `/api/organization/${payload.subCompany}/employeeWithRole/?role=${Role.STAFF_SALES_SUPPORT_GROUP}`,
      )
      .then(response => {
        setStaffList(response.data);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          setErrorMsg(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          setErrorMsg(error.response.message);
        } else {
          setErrorMsg(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
        setSubmitStatus(SubmitStatus.FAILURE);
      });
  };

  const assignHandler = (employeeId, employeeName) => {
    authHttpInteractor
      .patch(`/api/saleProperty/${propertyId}/transactionHandler?transactionHandler=${employeeId}`)
      .then(() => {
        onAssignDone(employeeName);
        onCloseFn();
        setSubmitStatus(SubmitStatus.SUCCESS);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          updateErrorMessage(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          updateErrorMessage(error.response.message);
        } else {
          updateErrorMessage(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
        setSubmitStatus(SubmitStatus.FAILURE);
      });
  };

  const employeeColums = [
    {
      title: '名前',
      dataIndex: 'fullName',
      render: (_, record) => (
        <>
          {record.familyName} {record.givenName}
        </>
      ),
    },
    {
      title: 'メール',
      dataIndex: 'email',
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record) => (
        <Space wrap>
          <Button
            type="primary"
            onClick={() => {
              assignHandler(record.id, `${record.familyName} ${record.givenName}`);
            }}
          >
            アサイン
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (isOpen) {
      fetchStaffList();
    }
  }, [isOpen]);

  return (
    <Drawer
      title="事務担当者をアサイン"
      width={600}
      onClose={onCloseFn}
      open={isOpen}
      bodyStyle={{
        paddingBottom: 80,
      }}
    >
      {/* FIXME GlobalLayout に移動 */}
      {/* エラーメッセージ */}
      {submitStatus === SubmitStatus.FAILURE && (
        <div style={{ width: '100%', marginBottom: 30 }}>
          <Alert message="エラー" description={errorMsg} type="error" showIcon />
        </div>
      )}

      <Table columns={employeeColums} dataSource={staffList} rowKey="id" pagination={false} />
    </Drawer>
  );
}
