import React, { useEffect, useState } from 'react';

import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { Alert, Button, Card, List, Pagination, Space, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { SubmitStatus } from '../../constants';
import { useAuth } from '../../hooks/useAuth';
import authHttpInteractor from '../../interactors/authHttpInteractor';
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout';
import { ValidationUtility } from '../../utilities/ValidationUtility';

import { SendMessageDrawer } from './parts/SendMessageDrawer';

import 'react-quill/dist/quill.snow.css';

function ReceivedMessageListPage() {
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const pageNo = pageParam && ValidationUtility.isNumber(pageParam) ? pageParam : 1;

  // TODO 共通化
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const [errorMsg, setErrorMsg] = useState();
  const { canSendOrgMsg } = useAuth();
  const [receivedMessage, setReceivedMessage] = useState([]);

  // 送信Drawer開閉
  const [openSendMessageDrawer, setOpenSendMessageDrawer] = useState(false);

  const fetchReceivedMessage = () => {
    authHttpInteractor
      .get(`/api/internalMessage/received?pageNo=${pageNo}`)
      .then(response => {
        console.log(response.data);
        setReceivedMessage(response.data);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        setSubmitStatus(SubmitStatus.FAILURE);

        setErrorMsg(
          <>
            大変申し訳ありませんが、エラーを発生しました。
            <br />
            通信状況をご確認の上、再度お試しください。
          </>,
        );
      });
  };

  // 受信メッセージ一覧を取得
  useEffect(() => {
    fetchReceivedMessage();
  }, []);

  const messageRead = messageMappingId => {
    authHttpInteractor
      .patch(`/api/internalMessageMapping/${messageMappingId}/read`)
      .then(() => {
        // XXX: APIから再取得ではありません。
        setReceivedMessage({
          ...receivedMessage,
          messageList: receivedMessage.messageList.map(message =>
            message.internalMessageMappingId === messageMappingId
              ? { ...message, hasBeenRead: 1 }
              : message,
          ),
        });
      })
      .catch(error => {
        // 既読処理失敗しても、ユーザーにお知らせ必要がない
        if (process.env.NODE_ENV === 'development') console.log(error);
      });
  };

  const columns = [
    {
      title: '送信者',
      dataIndex: 'sender',
      render: (_, record) => (
        // 未読は太文字で表示
        <span style={{ fontWeight: record.hasBeenRead === 0 ? 'bold' : 'normal' }}>
          {record.senderFamilyName} {record.senderGivenName}
        </span>
      ),
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      render: (_, record) => (
        <span style={{ fontWeight: record.hasBeenRead === 0 ? 'bold' : 'normal' }}>
          {record.title}
        </span>
      ),
    },
    {
      title: '送信日時',
      dataIndex: 'messageCreatedAt',
      render: (_, record) => (
        <span style={{ fontWeight: record.hasBeenRead === 0 ? 'bold' : 'normal' }}>
          {dayjs(record.createdAt).format('YYYY/MM/DD HH:mm:ss')}
        </span>
      ),
    },
    {
      title: '更新日時',
      dataIndex: 'messageUpdatedAt',
      render: (_, record) => (
        <span style={{ fontWeight: record.hasBeenRead === 0 ? 'bold' : 'normal' }}>
          {dayjs(record.updatedAt).format('YYYY/MM/DD HH:mm:ss')}
        </span>
      ),
    },
    {
      title: '既読',
      dataIndex: 'messageUpdatedAt',
      render: (_, record) => (
        <span style={{ fontWeight: record.hasBeenRead === 0 ? 'bold' : 'normal' }}>
          {record.hasBeenRead === 0 ? '未読' : '既読'}
        </span>
      ),
    },
  ];

  const content = () => (
    <div>
      {/* FIXME GlobalLayout に移動 */}
      {/* エラーメッセージ */}
      {submitStatus === SubmitStatus.FAILURE && (
        <div style={{ width: 800, marginBottom: 30 }}>
          <Alert message="エラー" description={errorMsg} type="error" showIcon />
        </div>
      )}

      <div style={{ width: 800 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Space wrap>
            <Title level={4}>受信箱</Title>
            {canSendOrgMsg() && (
              <Button
                type="primary"
                onClick={() => {
                  setOpenSendMessageDrawer(true);
                }}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                メッセージ作成
              </Button>
            )}
          </Space>
          {canSendOrgMsg() && (
            <Button type="link" size="large" icon={<SendOutlined />} href="/message/sent/">
              送信箱 ＞
            </Button>
          )}
        </div>

        <SendMessageDrawer
          isOpen={openSendMessageDrawer}
          onClose={() => {
            setOpenSendMessageDrawer(false);
          }}
          onEditSuccess={() => {
            setOpenSendMessageDrawer(false);
            fetchReceivedMessage();
          }}
        />

        <Table
          columns={columns}
          expandable={{
            expandedRowRender: record => (
              <>
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: record.body }} />
                {record.attachedFiles.length > 0 && (
                  <Card>
                    <List
                      header={<div>添付ファイル</div>}
                      itemLayout="horizontal"
                      size="small"
                      dataSource={record.attachedFiles}
                      renderItem={file => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<PaperClipOutlined />}
                            title={<a href={file.downloadUrl}>{file.name}</a>}
                          />
                        </List.Item>
                      )}
                    />
                  </Card>
                )}
              </>
            ),
            // rowExpandable: record => record.name !== 'Not Expandable',
          }}
          dataSource={receivedMessage.messageList}
          pagination={false}
          onExpand={(expanded, record) => {
            // 展開の場合のみ、既読処理を行う
            if (record.hasBeenRead === 0 && expanded) {
              messageRead(record.internalMessageMappingId);
            }
          }}
          rowKey="internalMessageId"
        />

        <Pagination
          total={receivedMessage.totalCount}
          current={receivedMessage.pageNo}
          defaultPageSize={receivedMessage.pageSize}
          showSizeChanger={false}
          onChange={page => {
            window.location.href = `/message/inbox/?page=${page}`;
          }}
          style={{ marginTop: 20 }}
        />
      </div>
    </div>
  );

  const breadcrumbList = [
    {
      title: <a href="/">トップ</a>,
    },
    {
      title: '受信箱',
    },
  ];

  return GlobalLayout(content(), breadcrumbList);
}

export default ReceivedMessageListPage;
