import React, { useContext, useState } from 'react';

import { FolderAddOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Radio, Result, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { YakInput } from '../../../components/YakInput';
import {
  AllowFlagLabel,
  PropertyInHouseAdvertisingPlatformLabel,
  ReinsAdvertisingTypeLabel,
  RentalPropertyType,
  RentalPropertyTypeLabel,
  RentalTransactionMode,
  RentalTransactionModeLabel,
  RentalTransactionStatus,
  RentalTransactionStatusLabel,
  RentalViewingMethod,
  RentalViewingMethodLabel,
  SubmitStatus,
  TransactionType,
  YesNoFlag,
  YesNoFlagLabel,
} from '../../../constants';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { useAuth } from '../../../hooks/useAuth';
import authHttpInteractor from '../../../interactors/authHttpInteractor';
import { CommonUtility } from '../../../utilities/CommonUtility';

const { Option } = Select;

export function InputChintaiProperty({ salesEmployeeList }) {
  const { updateErrorMessage } = useContext(ErrorContext);
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const [form] = Form.useForm();
  const { getJwtPayload } = useAuth();

  const currentInHouseAdvertising = Form.useWatch('inHouseAdvertising', form);
  const currentPropertyType = Form.useWatch('propertyType', form);
  const currentTransactionStatus = Form.useWatch('transactionStatus', form);
  const currentViewingMethod = Form.useWatch('viewingMethod', form);

  const insertChintaiProperty = data => {
    authHttpInteractor
      .put(`/api/rentalProperty`, data)
      .then(() => {
        setSubmitStatus(SubmitStatus.SUCCESS);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          updateErrorMessage(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          updateErrorMessage(error.response.message);
        } else {
          updateErrorMessage(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
        setSubmitStatus(SubmitStatus.FAILURE);
      });
  };

  const onValidateOk = values => {
    const payload = getJwtPayload();
    const requestData = {
      transactionType: TransactionType.RENTAL,
      transactionMode: values.transactionMode,
      mainContactPerson: values.mainContactPerson,
      contactPersonList: values.contactPersonList
        ? values.contactPersonList.map(val => ({ employeeId: val }))
        : [],
      propertyType: values.propertyType,
      name: CommonUtility.replaceFullNumber2Half(values.name),
      // 物件種別 は 賃貸マンション・賃貸外一 の場合、部屋番号
      ...([
        RentalPropertyType.APARTMENT.toString(),
        RentalPropertyType.OTHER_PROPERTY.toString(),
      ].includes(values.propertyType)
        ? { roomNumber: CommonUtility.replaceFullNumber2Half(values.roomNumber) }
        : {}),
      address: CommonUtility.replaceFullNumber2Half(values.address),
      rent: values.rent,
      managementFee: values.managementFee,
      keyMoney: values.keyMoney,
      securityDeposit: values.securityDeposit,
      systemFee: values.systemFee,
      inHouseAdvertising: values.inHouseAdvertising,

      // 自社広告掲載 有り の場合、追加項目
      ...(values.inHouseAdvertising === YesNoFlag.YES.toString()
        ? {
            reinsAdvertising: values.reinsAdvertising,
            inHouseAdvertisingPlatformList: values.inHouseAdvertisingPlatformList.map(val => ({
              platformId: val,
            })),
            transactionStatus: values.transactionStatus,
            // 取引状態が 申込あり の場合
            ...(values.transactionStatus === RentalTransactionStatus.APPLICATION_RECEIVED.toString()
              ? {
                  nextApplicationNo: values.nextApplicationNo,
                }
              : {}),
            viewingMethod: {
              viewingMethod: values.viewingMethod,

              // 店頭鍵貸出 の場合
              ...(values.viewingMethod === RentalViewingMethod.KEY_LENDING_AT_THE_STORE.toString()
                ? {
                    entrustedPartyContactMethod: CommonUtility.replaceFullNumber2Half(
                      values.entrustedPartyContactMethod,
                    ),
                    entrustedPartyLocation: CommonUtility.replaceFullNumber2Half(
                      values.entrustedPartyLocation,
                    ),
                    entrustedPartyName: CommonUtility.replaceFullNumber2Half(
                      values.entrustedPartyName,
                    ),
                  }
                : {}),
              // 内見不可 の場合
              ...(values.viewingMethod === RentalViewingMethod.NO_VIEWING.toString()
                ? {
                    viewingNotPossibleReason: CommonUtility.replaceFullNumber2Half(
                      values.viewingNotPossibleReason,
                    ),
                    viewingPossibleTime: CommonUtility.replaceFullNumber2Half(
                      values.viewingPossibleTime,
                    ),
                  }
                : {}),
              // 現地対応
              ...(values.viewingMethod === RentalViewingMethod.ON_SITE_SUPPORT.toString()
                ? {
                    keyBoxLocation: CommonUtility.replaceFullNumber2Half(values.keyBoxLocation),
                    keyBoxPassword: CommonUtility.replaceFullNumber2Half(values.keyBoxPassword),
                  }
                : {}),
            },
            thirdPartyAdvertising: values.thirdPartyAdvertising,
          }
        : {}),
      // 【備考】
      ...(values.remarks ? { remarks: values.remarks } : {}),

      // XXX BEでやるべき？
      submitter: payload.employeeId,
    };

    insertChintaiProperty(requestData);
  };

  return (
    <div>
      {/* 登録成功 */}
      {submitStatus === SubmitStatus.SUCCESS && (
        <div style={{ width: '100%' }}>
          <Result
            status="success"
            title="賃貸物件の登録は完了しました！"
            extra={[
              <Button
                onClick={() => {
                  form.resetFields();
                  setSubmitStatus(SubmitStatus.INITIAL);
                }}
                key="registerMore"
              >
                続けて登録
              </Button>,
              <Button href="/property/chintai/" key="chintaiList">
                賃貸物件一覧へ
              </Button>,
              <Button href="/my/property/chintai/" key="myChintaiList">
                賃貸担当案件一覧へ
              </Button>,
            ]}
          />
        </div>
      )}

      {submitStatus !== SubmitStatus.SUCCESS && (
        <Form
          name="createChintaiProperty"
          form={form}
          disabled={submitStatus === SubmitStatus.INPROGRESS}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onValidateOk}
          onFinishFailed={({ errorFields }) => {
            CommonUtility.scrollToById(`createChintaiProperty_${errorFields[0].name}`);
          }}
        >
          <Form.Item
            name="transactionMode"
            label="取引態様"
            hasFeedback
            style={{ marginBottom: 30 }}
            rules={[{ required: true, message: '取引態様を選択してください' }]}
          >
            <Radio.Group size="large">
              {[
                RentalTransactionMode.SOURCING.toString(),
                RentalTransactionMode.DUAL_AGENCY.toString(),
              ].map(mode => (
                <Radio value={mode} key={mode}>
                  {RentalTransactionModeLabel[mode]}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <>
            <Form.Item
              name="mainContactPerson"
              label="メイン担当者"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: 'メイン担当者を選択してください' }]}
            >
              <Select
                size="large"
                showSearch
                optionFilterProp="children"
                placeholder="メイン担当者を選択してください"
                filterOption={(input, option) =>
                  CommonUtility.removeAllSpace((option?.label ?? '').toLowerCase()).includes(
                    CommonUtility.removeAllSpace(input.toLowerCase()),
                  )
                }
                options={salesEmployeeList}
              />
            </Form.Item>

            <Form.Item
              name="contactPersonList"
              label="担当者"
              hasFeedback
              style={{ marginBottom: 30 }}
              // メイン担当が変わったらバリデーションを行う
              dependencies={['mainContactPerson']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, values) {
                    // メイン担当者として選択されたら、担当者として選択不可
                    if (
                      values &&
                      getFieldValue('mainContactPerson') &&
                      values.includes(getFieldValue('mainContactPerson'))
                    ) {
                      return Promise.reject(new Error('メイン担当者以外の方を選択してください'));
                    }

                    // 最大５人まで選択できる
                    if (values && values.length > 5) {
                      return Promise.reject(new Error('５人以下選択してください'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                size="large"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                placeholder="担当者を選択してください"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().trim().includes(input.toLowerCase().trim())
                }
                options={salesEmployeeList}
              />
            </Form.Item>

            <Form.Item
              name="propertyType"
              label="物件種別"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '物件種別を選択してください' }]}
            >
              <Select size="large" placeholder="物件種別を選択してください">
                {Object.keys(RentalPropertyTypeLabel).map(type => (
                  <Option key={type} value={type}>
                    {RentalPropertyTypeLabel[type]}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="name"
              label="物件名"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '物件名を入力してください' },
                {
                  max: 100,
                  message: '物件名は100文字以内で入力してください',
                },
              ]}
            >
              <YakInput
                setval={value => {
                  form.setFieldValue('name', value);
                }}
                size="large"
                maxLength={100}
                placeholder="例：岩本ビル"
              />
            </Form.Item>

            {[
              RentalPropertyType.APARTMENT.toString(),
              RentalPropertyType.OTHER_PROPERTY.toString(),
            ].includes(currentPropertyType) && (
              <Form.Item
                name="roomNumber"
                label="部屋番号"
                hasFeedback
                style={{ marginBottom: 30 }}
                rules={[
                  { required: true, message: '部屋番号を入力してください' },
                  {
                    max: 100,
                    message: '部屋番号は100文字以内で入力してください',
                  },
                ]}
              >
                <YakInput
                  setval={value => {
                    form.setFieldValue('roomNumber', value);
                  }}
                  size="large"
                  maxLength={100}
                  placeholder="例：３０２号室"
                />
              </Form.Item>
            )}

            <Form.Item
              name="address"
              label="住所"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '住所を入力してください' },
                {
                  max: 100,
                  message: '住所は100文字以内で入力してください',
                },
              ]}
            >
              <YakInput
                setval={value => {
                  form.setFieldValue('address', value);
                }}
                size="large"
                maxLength={100}
                placeholder="例：東京都台東区上野5-8-8 YAK御徒町ビル"
              />
            </Form.Item>

            <Form.Item
              name="rent"
              label="賃料(円)"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '賃料を入力してください' },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: '賃料の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：158000" />
            </Form.Item>

            <Form.Item
              name="managementFee"
              label="共益費・管理費（円）"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '共益費・管理費を入力してください' },
                {
                  pattern: /^(0|[1-9][0-9]*)$/,
                  message: '共益費・管理費の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：8000" />
            </Form.Item>

            <Form.Item
              name="keyMoney"
              label="礼金（ヶ月）"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '礼金を入力してください' },
                {
                  pattern: /^([1-9]\d*|0)(\.\d+)?$/,
                  message: '礼金の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：1.5" />
            </Form.Item>

            <Form.Item
              name="securityDeposit"
              label="敷金（ヶ月）"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: '敷金を入力してください' },
                {
                  pattern: /^([1-9]\d*|0)(\.\d+)?$/,
                  message: '敷金の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：1.5" />
            </Form.Item>

            <Form.Item
              name="systemFee"
              label="AD+システム料（ヶ月）"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                { required: true, message: 'AD+システム料を入力してください' },
                {
                  pattern: /^([1-9]\d*|0)(\.\d+)?$/,
                  message: 'AD+システム料の形式が正しくありません',
                },
              ]}
            >
              <Input size="large" maxLength={20} placeholder="例：1.5" />
            </Form.Item>

            <Form.Item
              name="inHouseAdvertising"
              label="自社広告掲載"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[{ required: true, message: '自社広告掲載を選択してください' }]}
            >
              <Radio.Group size="large">
                {Object.keys(YesNoFlagLabel).map(type => (
                  <Radio value={type} key={type}>
                    {YesNoFlagLabel[type]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {currentInHouseAdvertising === YesNoFlag.YES.toString() && (
              <>
                <Form.Item
                  name="reinsAdvertising"
                  label="レインズ等の登録"
                  hasFeedback
                  style={{
                    marginBottom: 30,
                  }}
                  rules={[{ required: true, message: 'レインズ等の登録を選択してください' }]}
                >
                  <Select
                    size="large"
                    placeholder="レインズ等の登録を選択してください"
                    onChange={() => {
                      // XXX レインズ等の登録 変わったときに、広告プラットフォームがクリア必要
                      form.setFieldValue('inHouseAdvertisingPlatformList', []);
                    }}
                  >
                    {Object.keys(ReinsAdvertisingTypeLabel).map(type => (
                      <Option key={type} value={type}>
                        {ReinsAdvertisingTypeLabel[type]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="inHouseAdvertisingPlatformList"
                  label="広告プラットフォーム"
                  hasFeedback
                  style={{
                    marginBottom: 30,
                  }}
                  rules={[
                    { required: true, message: '広告プラットフォームをチェックしてください' },
                  ]}
                >
                  <Checkbox.Group
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 10,
                    }}
                    size="large"
                    options={Object.keys(PropertyInHouseAdvertisingPlatformLabel).map(platform => ({
                      label: PropertyInHouseAdvertisingPlatformLabel[platform],
                      value: platform,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  name="transactionStatus"
                  label="取引状態"
                  hasFeedback
                  initialValue={RentalTransactionStatus.IN_PROGRESS.toString()}
                  style={{ marginBottom: 30 }}
                  rules={[{ required: true, message: '取引状態を選択してください' }]}
                >
                  {/* XXX 新規登録の場合は取引中で固定、編集不可 */}
                  <Select size="large" disabled placeholder="取引状態を選択してください">
                    {Object.keys(RentalTransactionStatusLabel).map(status => (
                      <Option key={status} value={status}>
                        {RentalTransactionStatusLabel[status]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {currentTransactionStatus ===
                  RentalTransactionStatus.APPLICATION_RECEIVED.toString() && (
                  <Form.Item
                    name="nextApplicationNo"
                    label="次の申込は何番手"
                    hasFeedback
                    style={{ marginBottom: 30 }}
                    rules={[{ required: true, message: '次の申込は何番手を選択してください' }]}
                  >
                    <Select size="large" placeholder="次の申込は何番手を選択してください">
                      {[...Array(5)].map((_, i) => (
                        <Option key={`${i + 1}`} value={i + 1}>
                          {i + 1}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  name="viewingMethod"
                  label="内見方法"
                  hasFeedback
                  style={{ marginBottom: 30 }}
                  rules={[{ required: true, message: '内見方法を選択してください' }]}
                >
                  <Select size="large" placeholder="内見方法を選択してください">
                    {Object.keys(RentalViewingMethodLabel).map(method => (
                      <Option key={method} value={method}>
                        {RentalViewingMethodLabel[method]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {currentViewingMethod ===
                  RentalViewingMethod.KEY_LENDING_AT_THE_STORE.toString() && (
                  <>
                    <Form.Item
                      name="entrustedPartyName"
                      label="預り業者名"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '預り業者名を入力してください' },
                        {
                          max: 100,
                          message: '預り業者名は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('entrustedPartyName', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="entrustedPartyLocation"
                      label="預り業者場所"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '預り業者場所を入力してください' },
                        {
                          max: 100,
                          message: '預り業者場所は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('entrustedPartyLocation', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="entrustedPartyContactMethod"
                      label="預り業者連絡方法"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '預り業者連絡方法を入力してください' },
                        {
                          max: 100,
                          message: '預り業者連絡方法は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('entrustedPartyContactMethod', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                  </>
                )}

                {currentViewingMethod === RentalViewingMethod.NO_VIEWING.toString() && (
                  <>
                    <Form.Item
                      name="viewingNotPossibleReason"
                      label="不可の原因"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '不可の原因を入力してください' },
                        {
                          max: 100,
                          message: '不可の原因は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('viewingNotPossibleReason', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="viewingPossibleTime"
                      label="内見可能となる時間"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: '内見可能となる時間を入力してください' },
                        {
                          max: 100,
                          message: '内見可能となる時間は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('viewingPossibleTime', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                  </>
                )}

                {currentViewingMethod === RentalViewingMethod.ON_SITE_SUPPORT.toString() && (
                  <>
                    <Form.Item
                      name="keyBoxLocation"
                      label="キーボックスの保存場所"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: 'キーボックスの保存場所を入力してください' },
                        {
                          max: 100,
                          message: 'キーボックスの保存場所は100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('keyBoxLocation', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      name="keyBoxPassword"
                      label="キーボックスのパスワード"
                      hasFeedback
                      style={{ marginBottom: 30 }}
                      rules={[
                        { required: true, message: 'キーボックスのパスワードを入力してください' },
                        {
                          max: 100,
                          message: 'キーボックスのパスワードは100文字以内で入力してください',
                        },
                      ]}
                    >
                      <YakInput
                        setval={value => {
                          form.setFieldValue('keyBoxPassword', value);
                        }}
                        size="large"
                        maxLength={100}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  name="thirdPartyAdvertising"
                  label="他社広告掲載"
                  hasFeedback
                  style={{ marginBottom: 30 }}
                  rules={[{ required: true, message: '他社広告掲載を選択してください' }]}
                >
                  <Radio.Group size="large">
                    {Object.keys(AllowFlagLabel).map(type => (
                      <Radio value={type} key={type}>
                        {AllowFlagLabel[type]}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </>
            )}

            {/* 備考 */}
            <Form.Item
              name="remarks"
              label="備考"
              hasFeedback
              style={{ marginBottom: 30 }}
              rules={[
                {
                  max: 1000,
                  message: '備考を1000文字まで入力してください',
                },
              ]}
            >
              <TextArea rows={6} placeholder="備考を入力してください" maxLength={1000} />
            </Form.Item>

            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: 300 }}
                icon={<FolderAddOutlined />}
              >
                登録する
              </Button>
            </Form.Item>
          </>
        </Form>
      )}
    </div>
  );
}
