import lodash from 'lodash';

import { ContractStatus, OrganizationType } from '../constants';

export class CommonUtility {
  /**
   * 組織ツリーから社員Nodeを削除
   *
   * @param node
   * @returns
   */
  // eslint-disable-next-line consistent-return
  static removeEmployeeFromOrgTree = node => {
    const newNode = lodash.cloneDeep(node);

    if (lodash.isArray(newNode)) {
      return lodash
        .filter(newNode, item => item.type !== OrganizationType.EMPLOYEE)
        .map(this.removeEmployeeFromOrgTree);
    }
    if (lodash.isObject(newNode)) {
      if (newNode.childNodeList) {
        newNode.childNodeList = lodash
          .filter(newNode.childNodeList, item => item.type !== OrganizationType.EMPLOYEE)
          .map(this.removeEmployeeFromOrgTree);
      }
      return newNode;
    }
  };

  /**
   * 組織ツリーの社員のlinkedIdの頭にランダム文字列をつける
   *
   * @param data
   * @returns
   */
  static formatForEmployeeSelect = node => {
    const newNode = lodash.cloneDeep(node);

    if (newNode.type === OrganizationType.EMPLOYEE) {
      newNode.linkedId = `${Math.random().toString(32).substring(2)}_${newNode.linkedId}`;
    }

    if (newNode.childNodeList) {
      newNode.childNodeList = newNode.childNodeList
        .map(this.formatForEmployeeSelect)
        .filter(
          childNode =>
            !(
              childNode.type === OrganizationType.DEPARTMENT && childNode.childNodeList.length === 0
            ),
        );
    }

    return newNode;
  };

  // eslint-disable-next-line no-irregular-whitespace
  static removeAllSpace = val => val.replace(/　/g, '').replace(' ', '');

  static replaceFullNumber2Half = value =>
    value.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

  static scrollToById = id => {
    const errorElement = document.getElementById(id);

    if (errorElement) {
      window.scrollTo({
        top: errorElement.getBoundingClientRect().top + window.scrollY - 64 - 15, // 64: ヘッダーの高さ、15: 調整量
        behavior: 'smooth',
      });
    }
  };

  /**
   * 契約状態の変更は適切かを判断する
   * @param fromStatus
   * @param toStatus
   * @returns
   */
  static isContractStatusChangeCorrect = (fromStatus, toStatus) => {
    // 基本 低→高 はOK
    if (toStatus >= fromStatus) {
      // ただし、例外がある

      // 不備資料手配中(４)→契約資料作成完了・契約待ち（５）
      if (
        fromStatus === ContractStatus.INCOMPLETE_DOCUMENTATION_BEING_ARRANGED &&
        toStatus === ContractStatus.CONTRACT_DOCUMENTATION_CREATED_AWAITING_CONTRACT_SIGNING
      ) {
        return false;
      }

      return true;
    }
    // 基本、高→低 は不適切

    // ただし、例外がある
    // 一時停止（１０）から戻る可
    if (fromStatus === ContractStatus.TEMPORARILY_SUSPENDED) {
      return true;
    }

    // 不備資料手配中（４）→ 事務受け済、作成待ち（３）はOK
    if (
      fromStatus === ContractStatus.INCOMPLETE_DOCUMENTATION_BEING_ARRANGED &&
      toStatus === ContractStatus.RECEIVED_BY_ADMINISTRATION_AWAITING_CREATION
    ) {
      return true;
    }

    return false;
  };
}
