import React, { useContext, useEffect, useState } from 'react';

import { Card } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Role } from '../../constants';
import { ErrorContext } from '../../contexts/ErrorContext';
import { useAuth } from '../../hooks/useAuth';
import authHttpInteractor from '../../interactors/authHttpInteractor';
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout';

import { EditSalePropertyForm } from './parts/EditSalePropertyForm';

export function EditSalePropertyPage() {
  const { propertyId } = useParams();
  const [searchParams] = useSearchParams();
  const backToParam = searchParams.get('backTo');
  const isBackToContract = backToParam && backToParam === 'contract';
  const isBackToMy = backToParam && backToParam === 'my';

  const { updateErrorMessage } = useContext(ErrorContext);
  const { getJwtPayload } = useAuth();
  const [salesEmployeeList, setSalesEmployeeList] = useState();
  const [propertyDetail, setPropertyDetail] = useState();

  const navigate = useNavigate();

  // 物件情報を取得
  const fetchPropertyDetail = () => {
    authHttpInteractor
      .get(`/api/saleProperty/${propertyId}`)
      .then(response => {
        setPropertyDetail(response.data);
        console.log(response.data);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          updateErrorMessage(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          updateErrorMessage(error.response.message);
        } else {
          updateErrorMessage(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
      });
  };

  // 営業社員一覧を取得
  const fetchSalesEmployeeList = () => {
    const payload = getJwtPayload();
    authHttpInteractor
      .get(
        `/api/organization/${payload.subCompany}/employeeWithRole/?role=${Role.SALES},${Role.SALES_MANAGER}`,
      )
      .then(response => {
        console.log(response.data);
        setSalesEmployeeList(
          response.data.map(val => ({
            value: val.id,
            label: `${val.familyName} ${val.givenName}`,
          })),
        );
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          updateErrorMessage(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          updateErrorMessage(error.response.message);
        } else {
          updateErrorMessage(
            <>
              大変申し訳ありませんが、通信エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
      });
  };

  useEffect(() => {
    fetchPropertyDetail();
    fetchSalesEmployeeList();
  }, []);

  const content = () => (
    <div>
      <Card title={<div style={{ textAlign: 'center' }}>売買物件編集</div>} style={{ width: 800 }}>
        {propertyDetail && salesEmployeeList && (
          <EditSalePropertyForm
            saleProperty={propertyDetail}
            salesEmployeeList={salesEmployeeList}
            onEditSuccessFn={() => {
              navigate(
                `${isBackToMy ? '/my' : ''}/property/sale/${isBackToContract ? 'contract/' : ''}`,
              );
            }}
          />
        )}
      </Card>
    </div>
  );

  const breadcrumbList = [
    {
      title: <a href="/">トップ</a>,
    },
    {
      title: '売買物件編集',
    },
  ];

  return GlobalLayout(content(), breadcrumbList);
}
