import React, { createContext, useMemo, useState } from 'react';

export const ErrorContext = createContext();

// eslint-disable-next-line react/prop-types
export function ErrorContextProvider({ children }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [warnMessage, setWarnMessage] = useState('');

  const updateErrorMessage = message => {
    setErrorMessage(message);
  };
  const updateWarnMessage = message => {
    setWarnMessage(message);
  };

  const contextValue = useMemo(
    () => ({ errorMessage, warnMessage, updateErrorMessage, updateWarnMessage }),
    [errorMessage, warnMessage],
  );

  return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>;
}
