import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { accessTokenOptions, refreshTokenOptions } from '../hooks/useAuth';

const uploadHttpInteractor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getAccessToken = () => {
  const cookies = parseCookies();
  if (cookies && cookies.at) {
    return decodeURIComponent(cookies.at);
  }

  return null;
};

const getRefreshToken = () => {
  const cookies = parseCookies();
  if (cookies && cookies.rt) {
    return decodeURIComponent(cookies.rt);
  }

  return null;
};

const getJwtPayload = () => {
  const cookies = parseCookies();
  if (cookies && cookies.at) {
    return jwtDecode(cookies.at);
  }

  return {};
};

const refreshTokens = async () => {
  const payload = getJwtPayload();

  return axios
    .post(`/api/authToken/${payload.employeeId}/refresh`, {
      accessToken: getAccessToken(),
      refreshToken: getRefreshToken(),
    })
    .then(response => {
      const token = response.data;

      // アクセストークン
      setCookie(null, 'at', token.accessToken, accessTokenOptions);
      // リフレッシュトークン
      setCookie(null, 'rt', token.refreshToken, refreshTokenOptions);

      return response.data;
    })
    .catch(() => {
      // リフレッシュトークン失敗した場合ログイン画面へ遷移
      destroyCookie(null, 'at', accessTokenOptions);
      destroyCookie(null, 'rt', refreshTokenOptions);
      if (process.env.NODE_ENV === 'development')
        console.log('---jwt cookie destroyed with refresh failed---');
      window.location.href = '/login/';
    });
};

uploadHttpInteractor.interceptors.request.use(
  async config => {
    const headers = {
      ...config.headers,
      'Content-Type': 'multipart/form-data',
    };

    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (accessToken && refreshToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // アクセストークンとリフレッシュトークンがどちらか存在しない場合
      // トークンをクリアし、ログイン画面へリダイレクト
      destroyCookie(null, 'at', accessTokenOptions);
      destroyCookie(null, 'rt', refreshTokenOptions);
      if (process.env.NODE_ENV === 'development')
        console.log('---jwt cookie destroyed with cookie lost---');
      window.location.href = '/login/';
    }

    return {
      ...config,
      headers,
    };
  },
  error => Promise.reject(error),
);

uploadHttpInteractor.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;

      const newTokens = await refreshTokens();

      originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;

      return uploadHttpInteractor(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default uploadHttpInteractor;
