import React, { useContext, useEffect, useState } from 'react';

import {
  ApartmentOutlined,
  BulbOutlined,
  CalendarOutlined,
  CarOutlined,
  DownOutlined,
  FolderAddOutlined,
  HomeOutlined,
  KeyOutlined,
  LogoutOutlined,
  MailOutlined,
  OrderedListOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Alert, Avatar, Badge, Breadcrumb, Dropdown, Layout, Menu, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SubmitStatus } from '../../constants';
import { ErrorContext } from '../../contexts/ErrorContext';
import { useAuth } from '../../hooks/useAuth';
import authHttpInteractor from '../../interactors/authHttpInteractor';
import httpInteractor from '../../interactors/httpInteractor';

import styles from './GlobalLayout.module.scss';

import 'antd/dist/reset.css';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    label,
    key,
    icon,
    children,
  };
}

const myList = [
  // {
  //   label: 'アカウント情報編集',
  //   key: 'edit_profile',
  //   icon: <UserOutlined />,
  // },
  {
    label: 'パスワード変更',
    key: 'reset_pw',
    icon: <KeyOutlined />,
  },
  {
    label: 'ログアウト',
    key: 'logout',
    icon: <LogoutOutlined />,
  },
];

function GlobalLayout(children, breadcrumbList = []) {
  const { errorMessage, warnMessage, setErrorMsg, setSubmitStatus } = useContext(ErrorContext);
  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();
  const {
    removeJwtToken,
    isLoggedIn,
    getJwtPayload,
    isAdmin,
    isOrgAdmin,
    isSales,
    isSalesManager,
    isStaff,
    isStaffManager,
    isStaffSalesSupportGrp,
    isStaffGeneralStaffGrp
  } = useAuth();

  /**
   * サイドメニュー
   */
  // 組織管理関連
  const sideMenuList = [
    getItem('組織管理', 'org_admin', <ApartmentOutlined />, [
      getItem('組織図', '/organization/tree/', <TeamOutlined />),
    ]),
  ];

  // 管理者専用メニュー
  if (isAdmin() || isOrgAdmin()) {
    sideMenuList.push(
      getItem('管理者', 'admin', <SolutionOutlined />, [
        getItem('社員登録', '/admin/employee/create/', <UserAddOutlined />),
      ]),
    );
  }

  // 物件管理関連メニュー
  const propertyMenuList = []
    .concat(
      isAdmin() || isSales() || isSalesManager() || isStaff() || isStaffManager()
        ? [
            getItem('掲載中売買物件一覧', '/property/sale/', <OrderedListOutlined />),
            getItem('掲載中賃貸物件一覧', '/property/chintai/', <OrderedListOutlined />),
          ]
        : [],
    )
    .concat(
      isAdmin() || isSales() || isSalesManager() || isStaffSalesSupportGrp() || isStaffManager()
        ? [
            getItem('物件登録', '/property/register/', <FolderAddOutlined />)]
        : [],
    )
    .concat(
      isAdmin() || isStaffSalesSupportGrp() || isStaffManager()
        ? [
            getItem('売買契約案件一覧', '/property/sale/contract/', <OrderedListOutlined />)]
        : [],
    )
    .concat(
      [
        getItem('賃貸契約案件一覧', '/property/chintai/contract/', <OrderedListOutlined />)
      ]
    )
    .concat(
      isAdmin() || isSales() || isSalesManager()
        ? [
            getItem('売買担当案件一覧', '/my/property/sale/', <OrderedListOutlined />),
            getItem('賃貸担当案件一覧', '/my/property/chintai/', <OrderedListOutlined />),
          ]
        : [],
    );
  if (propertyMenuList.length > 0) {
    sideMenuList.push(getItem('物件管理', 'property_admin', <HomeOutlined />, propertyMenuList));
  }

  // 予約関連メニュー
  sideMenuList.push(
    getItem('予約', 'reserve', <CalendarOutlined />, [
      getItem('会議室・社用車・印鑑予約', '/resource/reserve/', <CarOutlined />),
    ]),
  );

  // Reins
  sideMenuList.push(
    getItem('Reins', 'reins', <BulbOutlined />, [
      getItem('Reins', '/reins', <BulbOutlined />),
    ]),
  );

  const onClickNotice = () => {
    navigate('/message/inbox/');
  };
  const onClickMy = e => {
    if (e.key === 'edit_profile') {
      navigate(`/account/edit/${getJwtPayload().employeeId}/`);
    } else if (e.key === 'reset_pw') {
      navigate('/password/reset/');
    } else if (e.key === 'logout') {
      removeJwtToken();
      window.location.href = '/login/';
    }
  };
  const onClickCompany = e => {
    console.log('click', e);
  };

  const onClickMenuItem = ({ key }) => {
    if (key === '/reins') {
      window.open(key, '_blank').focus();
    } else {
      window.location.href = key;
    }
  };

  const [unreadInternelMessageNumber, setUnreadInternelMessageNumber] = useState([]);
  useEffect(() => {
    if (isLoggedIn()) {
      authHttpInteractor
        .get(`/api/internalMessage/receivedUnread`)
        .then(response => {
          setUnreadInternelMessageNumber(response.data.totalCount);
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'development') console.log(error);

          setErrorMsg(
            <>
              大変申し訳ありませんが、エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        });
      }
  }, []);

  // 子会社一覧を取得
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    httpInteractor
      .get('/api/organization/subCompany')
      .then(response => {
        setCompanyList(
          response.data.map(company => ({
            key: company.id,
            label: company.name,
            disabled: isLoggedIn() && company.id.toString() === getJwtPayload().subCompany,
          })),
        );
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        setSubmitStatus(SubmitStatus.FAILURE);

        // 認証不要のため、特にユーザーに見せるメッセージがありませんので
        // 汎用的なメッセージを出します。
        setErrorMsg(
          <>
            大変申し訳ありませんが、エラーを発生しました。
            <br />
            通信状況をご確認の上、再度お試しください。
          </>,
        );
      });
  }, []);

  return (
    <Layout className={styles.container}>
      <div>
        {isLoggedIn() && (
          <Sider
            width={270}
            collapsible
            collapsed={collapsed}
            onCollapse={value => setCollapsed(value)}
            theme="dark"
            className={styles.sider}
          >
            <Menu theme="dark" onClick={onClickMenuItem} mode="inline" items={sideMenuList} />
          </Sider>
        )}
      </div>
      <Layout className="site-layout">
        <Header className={styles.header}>
          <a href="/" className={styles.logo}>
            <img src="/assets/images/logo.png" alt="logo" />
            <span>YAK IT SYSTEM</span>
          </a>

          <div className={styles.gap} />

          {isLoggedIn() && (
            <>
              <div className={styles.notice} onClick={onClickNotice} aria-hidden>
                <Badge count={unreadInternelMessageNumber} overflowCount={99} offset={[10, 0]} size="small">
                  <MailOutlined className={styles.mail} />
                </Badge>
              </div>

              <Dropdown
                trigger="click"
                menu={{
                  items: myList,
                  onClick: onClickMy,
                }}
              >
                <div className={styles.my} onClick={onClickMy} aria-hidden>
                  <Space>
                    <Avatar className={styles.avatar} icon={<UserOutlined />} />
                    <span>{getJwtPayload() && getJwtPayload().name}</span>
                  </Space>
                </div>
              </Dropdown>

              <Dropdown
                trigger="click"
                menu={{
                  items: companyList,
                  onClick: onClickCompany,
                }}
              >
                <div className={styles.company}>
                  <Space>
                    <span>YAK株式会社</span>
                    <DownOutlined />
                  </Space>
                </div>
              </Dropdown>
            </>
          )}
        </Header>
        <Content className={styles.content}>
          <Breadcrumb items={breadcrumbList} className={styles.breadcrumb} />
          <div className={styles.inner}>
            {errorMessage && (
              <div style={{ width: 600, marginBottom: 30 }}>
                <Alert description={errorMessage} type="error" showIcon />
              </div>
            )}
            {warnMessage && (
              <div style={{ width: 600, marginBottom: 30 }}>
                <Alert description={warnMessage} type="warning" showIcon />
              </div>
            )}
            {children}
          </div>
        </Content>
        <Footer className={styles.footer}>Copyright (C) 2023 YAK All Rights Reserved</Footer>
      </Layout>
    </Layout>
  );
}

export default GlobalLayout;
