import React, { useState } from 'react';

import { HomeOutlined, MailOutlined, SendOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, Result } from 'antd';

import { SubmitStatus } from '../../constants';
import httpInteractor from '../../interactors/httpInteractor';
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout';

function PasswordResetPage() {
  // TODO 共通化
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.INITIAL);
  const [errorMsg, setErrorMsg] = useState();

  const onValidateOk = values => {
    const requestData = {
      email: values.email,
    };

    setSubmitStatus(SubmitStatus.INPROGRESS);

    httpInteractor
      .post('/api/employee/forgetPassword', requestData)
      .then(() => {
        setSubmitStatus(SubmitStatus.SUCCESS);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') console.log(error);

        if (error.response?.data?.errors) {
          // TODO 改行？
          setErrorMsg(error.response?.data?.errors.join(' '));
        } else if (error.response?.message) {
          setErrorMsg(error.response.message);
        } else {
          // TODO 確認；404(ユーザー存在しない)もこちらで良い？
          setErrorMsg(
            <>
              大変申し訳ありませんが、エラーを発生しました。
              <br />
              通信状況をご確認の上、再度お試しください。
            </>,
          );
        }
        setSubmitStatus(SubmitStatus.FAILURE);
      });
  };

  // const onValidateNg = errorInfo => {
  //   console.log('Failed:', errorInfo);
  // };

  const content = () => (
    <div>
      {/* FIXME GlobalLayout に移動 */}
      {/* エラーメッセージ */}
      {submitStatus === SubmitStatus.FAILURE && (
        <div style={{ width: 600, marginBottom: 30 }}>
          <Alert message="エラー" description={errorMsg} type="error" showIcon />
        </div>
      )}

      {/* 送信成功 */}
      {submitStatus === SubmitStatus.SUCCESS && (
        // メッセージと有効期限良い？
        <div style={{ width: 600 }}>
          <Result
            status="success"
            title="パスワード再設定のリクエストを受け付けました。"
            subTitle="ご入力されたメールアドレスにパスワード再設定用のリンクを利用してパスワードを設定してください。なお、URLの有効期限が24時間です。"
            extra={[
              <Button href="/" key="home" icon={<HomeOutlined />}>
                トップページへ
              </Button>,
            ]}
          />
        </div>
      )}
      {/* フォーム */}
      {submitStatus !== SubmitStatus.SUCCESS && (
        <Card
          title={<div style={{ textAlign: 'center' }}>パスワード変更</div>}
          style={{ width: 600 }}
        >
          <Form
            name="resetpw"
            // layout="vertical"
            requiredMark={false}
            onFinish={onValidateOk}
            // onFinishFailed={onValidateNg}
            disabled={submitStatus === SubmitStatus.INPROGRESS}
          >
            <Form.Item
              name="email"
              hasFeedback
              style={{ marginBottom: 25 }}
              rules={[
                { required: true, type: 'email', message: 'メールアドレスを入力してください' },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@(yak-jp\.com|yakas-jp\.com|yakdp-jp\.com|yakwl\.co\.jp|oak-jp\.com)$/,
                  message: 'メールアドレスの形式が正しくありません',
                },
                {
                  max: 319,
                  message: 'メールアドレスは319文字以内で入力してください',
                },
              ]}
            >
              <Input
                size="large"
                maxLength={319}
                placeholder="メールアドレスを入力してください"
                prefix={<MailOutlined />}
              />
            </Form.Item>

            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={submitStatus === SubmitStatus.INPROGRESS}
                size="large"
                style={{ width: 300 }}
                icon={<SendOutlined />}
              >
                送信する
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </div>
  );

  return GlobalLayout(content());
}

export default PasswordResetPage;
