export const SubmitStatus = {
  INITIAL: 'INITIAL',
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const EmployeeStatus = {
  TEMPORARY: 'TEMPORARY',
  NORMAL: 'NORMAL',
  LOCK: 'LOCK',
  DELETE: 'DELETE',
};

export const OrganizationType = {
  EMPLOYEE: 'EMPLOYEE',
  SUB_COMPANY: 'SUB_COMPANY',
  DEPARTMENT: 'DEPARTMENT',
};

export const Role = {
  ORG_ADMIN: 'ORG_ADMIN',
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
  SEND_ORG_MSG: 'SEND_ORG_MSG',
  STAFF: 'STAFF',
  SALES: 'SALES',
  STAFF_MANAGER: 'STAFF_MANAGER',
  SALES_MANAGER: 'SALES_MANAGER',
  STAFF_SALES_SUPPORT_GROUP: 'STAFF_SALES_SUPPORT_GROUP',
  STAFF_GENERAL_STAFF_GROUP: 'STAFF_GENERAL_STAFF_GROUP',
};
export const RoleLabel = {
  [Role.ADMIN]: '管理者',
  [Role.ORG_ADMIN]: '組織管理者',
  [Role.SALES]: '営業',
  [Role.SALES_MANAGER]: '営業管理職',
  [Role.STAFF]: '事務',
  [Role.STAFF_SALES_SUPPORT_GROUP]: '契約推進グループ',
  [Role.STAFF_GENERAL_STAFF_GROUP]: '総務グループ',
  [Role.STAFF_MANAGER]: '事務管理職',
  [Role.EMPLOYEE]: '従業員',
  [Role.SEND_ORG_MSG]: 'メッセージ送信',
};
export const RoleDesc = {
  [Role.ADMIN]: 'すべての権限を持つロール',
  [Role.ORG_ADMIN]: '組織管理者',
  [Role.SALES]: '営業',
  [Role.SALES_MANAGER]: '営業管理職',
  [Role.STAFF]: '事務',
  [Role.STAFF_SALES_SUPPORT_GROUP]: '契約推進グループの事務',
  [Role.STAFF_GENERAL_STAFF_GROUP]: '総務グループの事務',
  [Role.STAFF_MANAGER]: '事務管理職',
  [Role.EMPLOYEE]: '全従業員に発行するロール',
  [Role.SEND_ORG_MSG]: '社内メッセージ送信可能',
};

// sortタイプ
export const SortType = {
  // sort by createdAt
  CREATED_AT: 1,
  // sort By main contact person
  MAIN_CONTACT_PERSON: 2,
  // sort By contractStatus
  MAIN_CONTRACT_STATUS: 3,
};

export const TransactionType = {
  RENTAL: 'RENTAL',
  SALE: 'SALE',
};

// 売主買主種類
export const SellerBuyerType = {
  SELLER: 1,
  BUYER: 2,
};

export const PropertyContactPersonType = {
  // 主担当
  MAIN: 1,
  // 副担当
  SUB: 2,
};

export const ContractStatus = {
  // 新規
  NEW: 1,
  // 事務受付待ち:
  WAITING_FOR_ADMINISTRATIVE_PROCESSING: 2,
  // 事務受け済、作成待ち:
  RECEIVED_BY_ADMINISTRATION_AWAITING_CREATION: 3,
  // 不備資料手配中:
  INCOMPLETE_DOCUMENTATION_BEING_ARRANGED: 4,
  // 契約資料作成完了・契約待ち:
  CONTRACT_DOCUMENTATION_CREATED_AWAITING_CONTRACT_SIGNING: 5,
  // 契約済み・本審査中:
  CONTRACT_SIGNED_CURRENTLY_UNDER_FINAL_REVIEW: 6,
  // 金消契約:
  PAYMENT_COMPLETED_CONTRACT_IN_EFFECT: 7,
  // 決済待ち:
  WAITING_FOR_PAYMENT_SETTLEMENT: 8,
  // 決済完了:
  PAYMENT_SETTLED: 9,
  // 一時停止:
  TEMPORARILY_SUSPENDED: 10,
  // 契約取り消し:
  CONTRACT_CANCELED: 11,
};
export const ContractStatusLabel = {
  [ContractStatus.NEW]: '新規',
  [ContractStatus.WAITING_FOR_ADMINISTRATIVE_PROCESSING]: '事務受付待ち',
  [ContractStatus.RECEIVED_BY_ADMINISTRATION_AWAITING_CREATION]: '事務受け済、作成待ち',
  [ContractStatus.INCOMPLETE_DOCUMENTATION_BEING_ARRANGED]: '不備資料手配中',
  [ContractStatus.CONTRACT_DOCUMENTATION_CREATED_AWAITING_CONTRACT_SIGNING]:
    '契約資料作成完了・契約待ち',
  [ContractStatus.CONTRACT_SIGNED_CURRENTLY_UNDER_FINAL_REVIEW]: '契約済み・本審査中',
  [ContractStatus.PAYMENT_COMPLETED_CONTRACT_IN_EFFECT]: '金消契約',
  [ContractStatus.WAITING_FOR_PAYMENT_SETTLEMENT]: '決済待ち',
  [ContractStatus.PAYMENT_SETTLED]: '決済完了',
  [ContractStatus.TEMPORARILY_SUSPENDED]: '一時停止',
  [ContractStatus.CONTRACT_CANCELED]: '契約取り消し',
};

export const RentalPropertyType = {
  // 賃貸マンション
  APARTMENT: 1,
  // 賃貸外全
  COMMERCIAL_PROPERTY: 2,
  // 賃貸戸建て
  HOUSE: 3,
  // 賃貸外一
  OTHER_PROPERTY: 4,
  // 賃貸土地
  LAND: 5,
};
export const RentalPropertyTypeLabel = {
  [RentalPropertyType.APARTMENT]: '賃貸マンション',
  [RentalPropertyType.COMMERCIAL_PROPERTY]: '賃貸外全',
  [RentalPropertyType.HOUSE]: '賃貸戸建て',
  [RentalPropertyType.OTHER_PROPERTY]: '賃貸外一',
  [RentalPropertyType.LAND]: '賃貸土地',
};

export const RentalTransactionMode = {
  // 元付け
  SOURCING: 1,
  // 客付け
  PLACEMENT: 2,
  // 両手
  DUAL_AGENCY: 3,
  // 自社管理物件
  IN_HOUSE_MANAGED_PROPERTIES: 4,
};
export const RentalTransactionModeLabel = {
  [RentalTransactionMode.SOURCING]: '元付け',
  [RentalTransactionMode.PLACEMENT]: '客付け',
  [RentalTransactionMode.DUAL_AGENCY]: '両手',
  [RentalTransactionMode.IN_HOUSE_MANAGED_PROPERTIES]: '自社管理物件',
};

export const RentalTransactionStatus = {
  // 取引中
  IN_PROGRESS: 1,
  // 申込あり
  APPLICATION_RECEIVED: 2,
  // 契約予定
  CONTRACT_PLANNED: 3,
  // 一時停止
  TEMPORARILY_PAUSED: 4,
  // 取引完了
  TRANSACTION_COMPLETED: 5,
};
export const RentalTransactionStatusLabel = {
  [RentalTransactionStatus.IN_PROGRESS]: '取引中',
  [RentalTransactionStatus.APPLICATION_RECEIVED]: '申込あり',
  [RentalTransactionStatus.CONTRACT_PLANNED]: '契約予定',
  [RentalTransactionStatus.TEMPORARILY_PAUSED]: '一時停止',
  [RentalTransactionStatus.TRANSACTION_COMPLETED]: '取引完了',
};

export const SalePropertyType = {
  // 売マンション
  CONDOMINIUM: 1,
  // 売一戸建て
  HOUSE: 2,
  // 売地
  LAND: 3,
  // 売ビル
  BUILDING: 4,
  // 売アパート
  APARTMENT: 5,
  // 売外一、外全
  COMMERCIAL_PROPERTY: 6,
};
export const SalePropertyTypeLabel = {
  [SalePropertyType.CONDOMINIUM]: '売マンション',
  [SalePropertyType.HOUSE]: '売一戸建て',
  [SalePropertyType.LAND]: '売地',
  [SalePropertyType.BUILDING]: '売ビル',
  [SalePropertyType.APARTMENT]: '売アパート',
  [SalePropertyType.COMMERCIAL_PROPERTY]: '売外一、外全',
};

export const SalePropertyVacancyStatus = {
  // 空室
  VACANT: 1,
  // 居住中
  OCCUPIED: 2,
  // 賃貸中
  RENTED: 3,
  // 更地
  VACANT_LAND: 4,
  // 古屋あり
  WITH_EXISTING_BUILDING: 5,
  // リフォーム中
  UNDER_RENOVATION: 6,
  // 寮
  DORMITORY: 7,
  // 民泊運営中
  OPERATION_AS_A_VACATION_RENTAL: 8,
};
export const SalePropertyVacancyStatusLabel = {
  [SalePropertyVacancyStatus.VACANT]: '空室',
  [SalePropertyVacancyStatus.OCCUPIED]: '居住中',
  [SalePropertyVacancyStatus.RENTED]: '賃貸中',
  [SalePropertyVacancyStatus.VACANT_LAND]: '更地',
  [SalePropertyVacancyStatus.WITH_EXISTING_BUILDING]: '古屋あり',
  [SalePropertyVacancyStatus.UNDER_RENOVATION]: 'リフォーム中',
  [SalePropertyVacancyStatus.DORMITORY]: '寮',
  [SalePropertyVacancyStatus.OPERATION_AS_A_VACATION_RENTAL]: '民泊運営中',
};

export const SaleTransactionMode = {
  // 元付け
  SOURCING: 1,
  // 客付け
  PLACEMENT: 2,
  // 両手
  DUAL_AGENCY: 3,
  // 仕入れ
  PROCUREMENT: 4,
  // 再販
  RESALE: 5,
};
export const SaleTransactionModeLabel = {
  [SaleTransactionMode.SOURCING]: '元付け',
  [SaleTransactionMode.PLACEMENT]: '客付け',
  [SaleTransactionMode.DUAL_AGENCY]: '両手',
  [SaleTransactionMode.PROCUREMENT]: '仕入れ',
  [SaleTransactionMode.RESALE]: '再販',
};

export const SaleTransactionStatus = {
  // 取引中
  IN_PROGRESS: 1,
  // 契約予定
  CONTRACT_PLANNED: 2,
  // 契約済み
  CONTRACT_CONCLUDED: 3,
  // 一時停止
  TEMPORARILY_PAUSED: 4,
  // 決済予定
  PAYMENT_SCHEDULED: 5,
  // 再販売
  RESALE: 6,
  // 取引完了
  TRANSACTION_COMPLETED: 7,
};
export const SaleTransactionStatusLabel = {
  [SaleTransactionStatus.IN_PROGRESS]: '取引中',
  [SaleTransactionStatus.CONTRACT_PLANNED]: '契約予定',
  [SaleTransactionStatus.CONTRACT_CONCLUDED]: '契約済み',
  [SaleTransactionStatus.TEMPORARILY_PAUSED]: '一時停止',
  [SaleTransactionStatus.PAYMENT_SCHEDULED]: '決済予定',
  [SaleTransactionStatus.RESALE]: '再販売',
  [SaleTransactionStatus.TRANSACTION_COMPLETED]: '取引完了',
};

export const RentalViewingMethod = {
  // 店頭鍵貸出
  KEY_LENDING_AT_THE_STORE: 1,
  // 立会い
  ATTENDANCE: 2,
  // 内見不可
  NO_VIEWING: 3,
  // 現地対応
  ON_SITE_SUPPORT: 4,
};
export const RentalViewingMethodLabel = {
  [RentalViewingMethod.KEY_LENDING_AT_THE_STORE]: '店頭鍵貸出',
  [RentalViewingMethod.ATTENDANCE]: '立会い',
  [RentalViewingMethod.NO_VIEWING]: '内見不可',
  [RentalViewingMethod.ON_SITE_SUPPORT]: '現地対応',
};

export const SaleViewingMethod = {
  // 店頭鍵貸出
  KEY_LENDING_AT_THE_STORE: 1,
  // 立会い
  ATTENDANCE: 2,
  // 内見不可
  NO_VIEWING: 3,
  // 現地対応
  ON_SITE_SUPPORT: 4,
  // オーナーチェンジ
  OWNER_CHANGE: 5,
};
export const SaleViewingMethodLabel = {
  [SaleViewingMethod.KEY_LENDING_AT_THE_STORE]: '店頭鍵貸出',
  [SaleViewingMethod.ATTENDANCE]: '立会い',
  [SaleViewingMethod.NO_VIEWING]: '内見不可',
  [SaleViewingMethod.ON_SITE_SUPPORT]: '現地対応',
  [SaleViewingMethod.OWNER_CHANGE]: 'オーナーチェンジ',
};

export const ReinsAdvertisingType = {
  // 登録済み
  DONE: 1,
  // 登録予定
  PLANING: 2,
};
export const ReinsAdvertisingTypeLabel = {
  [ReinsAdvertisingType.DONE]: '登録済み',
  [ReinsAdvertisingType.PLANING]: '登録予定',
};

export const PropertyInHouseAdvertisingPlatform = {
  // レインズ（REINS）
  REINS: 1,
  // iTANDIBB
  I_TANDIBB: 2,
  // real estate japan
  REAL_ESTATE_JAPAN: 3,
  // SUUMO
  SUUMO: 4,
  // athome
  AT_HOME: 5,
  // HOMES
  HOMES: 6,
  // 株式会社スペースエージェット(民泊サイト)
  SPACE_AGENT: 7,
  // 楽待
  RAKUTAI: 8,
};
export const PropertyInHouseAdvertisingPlatformLabel = {
  [PropertyInHouseAdvertisingPlatform.REINS]: 'レインズ（REINS）',
  [PropertyInHouseAdvertisingPlatform.I_TANDIBB]: 'iTANDIBB',
  [PropertyInHouseAdvertisingPlatform.REAL_ESTATE_JAPAN]: 'real estate japan',
  [PropertyInHouseAdvertisingPlatform.SUUMO]: 'SUUMO',
  [PropertyInHouseAdvertisingPlatform.AT_HOME]: 'athome',
  [PropertyInHouseAdvertisingPlatform.HOMES]: 'HOMES',
  [PropertyInHouseAdvertisingPlatform.SPACE_AGENT]: '株式会社スペースエージェット(民泊サイト)',
  [PropertyInHouseAdvertisingPlatform.RAKUTAI]: '楽待',
};

// 有無,該当する/しない,負う/負わない
export const YesNoFlag = {
  // 有
  YES: 1,
  // 無
  NO: 2,
};
export const YesNoFlagLabel = {
  [YesNoFlag.YES]: '有',
  [YesNoFlag.NO]: '無',
};
export const AllowFlagLabel = {
  [YesNoFlag.YES]: '可',
  [YesNoFlag.NO]: '不可',
};
export const NeedFlagLabel = {
  [YesNoFlag.YES]: '必要',
  [YesNoFlag.NO]: '不要',
};

/**
 * 契約情報用
 */

// 名義
export const TitleType = {
  // 単独名義
  SINGLE: 1,
  // 共同名義
  JOINT: 2,
};
export const TitleTypeLabel = {
  [TitleType.SINGLE]: '単独名義',
  [TitleType.JOINT]: '共同名義',
};

// 顧客名義種類
export const CustomerTitleType = {
  // 一般個人
  INDIVIDUAL_CUSTOMER: 1,
  // 一般法人
  CORPORATE_CUSTOMER: 2,
  // 不動産業者
  REAL_ESTATE_COMPANY: 3,
};
export const CustomerTitleTypeLabel = {
  [CustomerTitleType.INDIVIDUAL_CUSTOMER]: '一般個人',
  [CustomerTitleType.CORPORATE_CUSTOMER]: '一般法人',
  [CustomerTitleType.REAL_ESTATE_COMPANY]: '不動産業者',
};

// 住所
export const AddressType = {
  // 日本国内
  IN_JAPAN: 1,
  // 海外
  OVER_SEA: 2,
};
export const AddressTypeLabel = {
  [AddressType.IN_JAPAN]: '日本国内',
  [AddressType.OVER_SEA]: '海外',
};

// 購入目的
export const PurchasePurposeType = {
  // 居住用/自用
  RESIDENTIAL_OR_SELF_USE: 1,
  // 収益用
  INCOME_GENERATING: 2,
};
export const PurchasePurposeTypeLabel = {
  [PurchasePurposeType.RESIDENTIAL_OR_SELF_USE]: '居住用・自用',
  [PurchasePurposeType.INCOME_GENERATING]: '収益用',
};

// 資金調達手段
export const FundingMethodType = {
  // 現金
  CASH: 1,
  // 融資住宅ローン
  HOUSING_LOAN: 2,
  // 融資投資ローン
  INVESTMENT_LOAN: 3,
};
export const FundingMethodTypeLabel = {
  [FundingMethodType.CASH]: '現金',
  [FundingMethodType.HOUSING_LOAN]: '融資住宅ローン',
  [FundingMethodType.INVESTMENT_LOAN]: '融資投資ローン',
};

// 融資金融機関一覧
export const FinancingBankList = {
  1: 'ワールドローン',
  2: '三井住友銀行',
  3: '三菱UFJ銀行',
  4: 'みずほ銀行',
  5: 'りそな銀行',
  6: 'SMBC信託銀行',
  7: '中国銀行',
  8: '中国交通銀行',
  9: 'オリックス',
  10: 'あすか信用組合',
  11: 'ARUHI',
  12: 'セゾンファンデックス',
  13: 'スルガ銀行',
  14: '城北信用金庫',
  15: 'SBJ銀行',
  16: '住信SBIネット銀行(株)',
  17: 'ソニー銀行',
  18: 'イオン銀行',
  19: '中央労働金庫',
  20: 'auじぶん銀行',
  21: '千葉銀行',
  22: '群馬銀行',
  23: 'フラット35',
  24: '日本モーケージ',
  25: '三井住友トラスト・ローン&ファイナンス(株)',
  26: 'その他',
};

export const RemainingItemsRemovalDisposerType = {
  // 買主
  SELLER: 1,
  // 売主
  BUYER: 2,
  // 要相談
  TO_BE_DISCUSSED: 3,
};
export const RemainingItemsRemovalDisposerTypeLabel = {
  [RemainingItemsRemovalDisposerType.SELLER]: '買主',
  [RemainingItemsRemovalDisposerType.BUYER]: '売主',
  [RemainingItemsRemovalDisposerType.TO_BE_DISCUSSED]: '要相談',
};

export const RemainingItemsRemovalCostResponsibilityType = {
  // 買主
  SELLER: 1,
  // 売主
  BUYER: 2,
  // 要相談
  TO_BE_DISCUSSED: 3,
};
export const RemainingItemsRemovalCostResponsibilityTypeLabel = {
  [RemainingItemsRemovalCostResponsibilityType.SELLER]: '買主',
  [RemainingItemsRemovalCostResponsibilityType.BUYER]: '売主',
  [RemainingItemsRemovalCostResponsibilityType.TO_BE_DISCUSSED]: '要相談',
};

// 中間省略種類
export const OmittedInBetweenType = {
  // なし
  NO: 1,
  // AB契約
  AB: 2,
  // BC契約
  BC: 3,
  // CD契約
  CD: 4,
  // DE契約
  DE: 5,
  // （ ）契約
  FREE: 6,
};
export const OmittedInBetweenTypeLabel = {
  [OmittedInBetweenType.NO]: 'なし',
  [OmittedInBetweenType.AB]: 'AB契約',
  [OmittedInBetweenType.BC]: 'BC契約',
  [OmittedInBetweenType.CD]: 'CD契約',
  [OmittedInBetweenType.DE]: 'DE契約',
  [OmittedInBetweenType.FREE]: 'その他',
};

// 契約方法
export const ContactMethodType = {
  // 同時契約
  JOINT_CONTRACT: 1,
  // 持ち回り契約
  ALTERNATING_CONTRACT: 2,
};
export const ContractMethodTypeLabel = {
  [ContactMethodType.JOINT_CONTRACT]: '同時契約',
  [ContactMethodType.ALTERNATING_CONTRACT]: '持ち回り契約',
};

// 契約場所
export const ContactLocationType = {
  // 自社
  IN_HOUSE: 1,
  // 相手業者会社
  COUNTERPARTY_COMPANY: 2,
  // その他
  OTHER: 3,
};
export const ContactLocationTypeLabel = {
  [ContactLocationType.IN_HOUSE]: '自社',
  [ContactLocationType.COUNTERPARTY_COMPANY]: '相手業者会社',
  [ContactLocationType.OTHER]: 'その他',
};

// 重要事項説明者
export const ImportantMatterExplainer = {
  // 自社宅建士
  IN_HOUSE_REAL_ESTATE_EXPERT: 1,
  // 相手業者宅建士
  COUNTERPARTY_COMPANY_REAL_ESTATE_EXPERT: 2,
};
export const ImportantMatterExplainerLabel = {
  [ImportantMatterExplainer.IN_HOUSE_REAL_ESTATE_EXPERT]: '自社宅建士',
  [ImportantMatterExplainer.COUNTERPARTY_COMPANY_REAL_ESTATE_EXPERT]: '相手業者宅建士',
};

// 重説作成者
export const DraftingOfImportantMatterExplanation = {
  // 自社担当
  IN_HOUSE: 1,
  // 相手業者
  COUNTERPARTY_COMPANY: 2,
};
export const DraftingOfImportantMatterExplanationLabel = {
  [DraftingOfImportantMatterExplanation.IN_HOUSE]: '自社担当',
  [DraftingOfImportantMatterExplanation.COUNTERPARTY_COMPANY]: '相手業者',
};

// 代理契約
export const AgencyAgreementType = {
  // 代理契約ない
  NO: 1,
  // 代理契約あり委任状取得済み
  DONE: 2,
  // 代理契約あり委任状未取得
  NOT_YET: 3,
};
export const AgencyAgreementTypeLabel = {
  [AgencyAgreementType.NO]: '代理契約ない',
  [AgencyAgreementType.DONE]: '代理契約あり（委任状取得済み）',
  [AgencyAgreementType.NOT_YET]: '代理契約あり（委任状未取得）',
};

// 海外海外入出金
export const OverseasMoneyTransferType = {
  // 海外から送金有
  FROM_OVERSEAS_REMITTANCE: 1,
  // 海外へ送金有
  TO_OVERSEAS_REMITTANCE: 2,
  // 入金送金両方あり
  BOTH_INCOMING_AND_OUTGOING_REMITTANCE: 3,
  // 入金送金両方無
  NO_INCOMING_OR_OUTGOING_REMITTANCE: 4,
};
export const OverseasMoneyTransferTypeLabel = {
  [OverseasMoneyTransferType.FROM_OVERSEAS_REMITTANCE]: '海外から送金有',
  [OverseasMoneyTransferType.TO_OVERSEAS_REMITTANCE]: '海外へ送金有',
  [OverseasMoneyTransferType.BOTH_INCOMING_AND_OUTGOING_REMITTANCE]: '入金送金両方あり',
  [OverseasMoneyTransferType.NO_INCOMING_OR_OUTGOING_REMITTANCE]: '入金送金両方無',
};

// 海外入金種類
export const OverseasRemittanceType = {
  // 中国本土
  CHINA_MAIN_LAND: 1,
  // 中国本土以外
  CHINA_NOT_MAIN_LAND: 2,
  // 中国以外
  NOT_CHINA: 3,
};
export const OverseasRemittanceTypeLabel = {
  [OverseasRemittanceType.CHINA_MAIN_LAND]: '中国本土',
  [OverseasRemittanceType.CHINA_NOT_MAIN_LAND]: '中国本土以外',
  [OverseasRemittanceType.NOT_CHINA]: '中国以外',
};

// 印紙負担
export const StampDutyResponsibility = {
  // 買主負担
  BUYER_BORNE: 1,
  // 売主負担
  SELLER_BORNE: 2,
  // 折半
  SPLIT_EQUALLY: 3,
};
export const StampDutyResponsibilityLabel = {
  [StampDutyResponsibility.BUYER_BORNE]: '買主負担',
  [StampDutyResponsibility.SELLER_BORNE]: '売主負担',
  [StampDutyResponsibility.SPLIT_EQUALLY]: '折半',
};

// 印紙準備
export const StampDutyPreparation = {
  // 印紙準備必要、領収書必要
  YES_WITH_RECEIPT: 1,
  // 印紙準備必要、領収書不要
  YES_WITHOUT_RECEIPT: 2,
  // 印紙準備不要
  NO: 3,
};
export const StampDutyPreparationLabel = {
  [StampDutyPreparation.YES_WITH_RECEIPT]: '印紙準備必要、領収書必要',
  [StampDutyPreparation.YES_WITHOUT_RECEIPT]: '印紙準備必要、領収書不要',
  [StampDutyPreparation.NO]: '印紙準備不要',
};

// 手数料支払方法
export const FeePaymentMethodType = {
  // 現金
  CASH: 1,
  // 振込
  TRANSFER: 2,
  // 手数料なし
  NO: 3,
};
// XXX 手数料なしは、チェック入れてない場合APIに渡す項目のため画面に出さない
export const FeePaymentMethodTypeLabel = {
  [FeePaymentMethodType.CASH]: '現金',
  [FeePaymentMethodType.TRANSFER]: '振込',
};

// 雑費計算式一覧
export const CalculateFormula = {
  // 1: '（売買金額-建物消費税）×5％×1.1',
  FORMULA_1: 1,
  // 2: '［（売買金額-建物消費税）×4％+20000］×1.1',
  FORMULA_2: 2,
  // 3: '［（売買金額-建物消費税）×3％+60000］×1.1',
  FORMULA_3: 3,
  // 4: '（売買金額-建物消費税）×3％（税込）',
  FORMULA_4: 4,
  // 5: '（売買金額-建物消費税）×2％（税込）',
  FORMULA_5: 5,
  // 6: '（売買金額-建物消費税）×1％（税込）',
  FORMULA_6: 6,
  // 7: '（売買金額-建物消費税）×3％×1.1',
  FORMULA_7: 7,
  // 8: '（売買金額-建物消費税）×2％×1.1',
  FORMULA_8: 8,
  // 9: '（売買金額-建物消費税）×1％×1.1',
  FORMULA_9: 9,
  // 10: '（売買代金×2％+60000）×1.1',
  FORMULA_10: 10,
  // 11: '（売買代金×1.5％+60000）×1.1',
  FORMULA_11: 11,
  // 12: '（売買代金×1％+60000）×1.1',
  FORMULA_12: 12,
  // 13: '売買代金×3％×1.1',
  FORMULA_13: 13,
  // 14: '売買代金×2％×1.1',
  FORMULA_14: 14,
  // 15: '売買代金×1.5％×1.1',
  FORMULA_15: 15,
  // 16: '売買代金×1％×1.1',
  FORMULA_16: 16,
  // 17: 'その他',
  FORMULA_OTHER: 17,
};
export const CalculateFormulaLabel = {
  [CalculateFormula.FORMULA_1]: '（売買金額-建物消費税）×5％×1.1',
  [CalculateFormula.FORMULA_2]: '［（売買金額-建物消費税）×4％+20000］×1.1',
  [CalculateFormula.FORMULA_3]: '［（売買金額-建物消費税）×3％+60000］×1.1',
  [CalculateFormula.FORMULA_4]: '（売買金額-建物消費税）×3％（税込）',
  [CalculateFormula.FORMULA_5]: '（売買金額-建物消費税）×2％（税込）',
  [CalculateFormula.FORMULA_6]: '（売買金額-建物消費税）×1％（税込）',
  [CalculateFormula.FORMULA_7]: '（売買金額-建物消費税）×3％×1.1',
  [CalculateFormula.FORMULA_8]: '（売買金額-建物消費税）×2％×1.1',
  [CalculateFormula.FORMULA_9]: '（売買金額-建物消費税）×1％×1.1',
  [CalculateFormula.FORMULA_10]: '（売買代金×2％+60000）×1.1',
  [CalculateFormula.FORMULA_11]: '（売買代金×1.5％+60000）×1.1',
  [CalculateFormula.FORMULA_12]: '（売買代金×1％+60000）×1.1',
  [CalculateFormula.FORMULA_13]: '売買代金×3％×1.1',
  [CalculateFormula.FORMULA_14]: '売買代金×2％×1.1',
  [CalculateFormula.FORMULA_15]: '売買代金×1.5％×1.1',
  [CalculateFormula.FORMULA_16]: '売買代金×1％×1.1',
  [CalculateFormula.FORMULA_OTHER]: 'その他',
};

// 司法書士指定
export const JudicialScrivenerAppointing = {
  // ①売主指定
  SELLER: 1,
  // ②買主指定
  BUYER: 2,
  // ③両方指定無、重説に記載しない
  NONE: 3,
};
export const JudicialScrivenerAppointingLabel = {
  [JudicialScrivenerAppointing.SELLER]: '売主指定',
  [JudicialScrivenerAppointing.BUYER]: '買主指定',
  [JudicialScrivenerAppointing.NONE]: '両方指定無、重説に記載しない',
};
