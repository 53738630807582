import React from 'react';

import { Input } from 'antd';
import lodash from 'lodash';

export function YakInput(props) {
  const { setval } = props;

  const inputOptions = lodash.cloneDeep(props);
  delete inputOptions.setval;

  const handleChange = e => {
    const currentValue = e.target.value;

    setval(currentValue.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0)));
  };

  return <Input {...inputOptions} onChange={handleChange} />;
}
