import axios from 'axios';

const httpInteractor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpInteractor.interceptors.request.use(
  config => {
    const headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    return {
      ...config,
      headers,
    };
  },
  error => Promise.reject(error),
);

httpInteractor.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // TODO エラー処理をここのまとめ？！
    }
    return Promise.reject(error);
  },
);

export default httpInteractor;
